import React from "react";

const ProgressBar = (
  { showProgress, progressRef }: { showProgress: boolean; progressRef: React.MutableRefObject<number> }
) => {
  if (!showProgress) return null;

  return (
    <div className="scrap_process_main" id="progress-container">
      <div className="scrap_btn_process" style={{ width: "92%", zIndex: "9" }}>
        <div className="loaderBar" style={{ overflow: "hidden" }}>
          <div
            className="progressFill"
            id="progress-bar"
            style={{
              width: `${progressRef.current}%`,
              height: "100%",
              transition: "width 0.5s ease-in-out",
              backgroundColor: progressRef.current === 100 ? "#4caf50" : "#2196f3",
            }}
          ></div>
        </div>
        <p className="percentage" id="progress-text">
          {progressRef.current === 100 ? "" : `${progressRef.current}%`}
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
