import React, { useEffect, useState } from "react";
import { ChevronLeft  , Search  } from 'lucide-react';

import "./Translate_language.css";
const PUBLIC_PATH = 'https://demo.turabit.com/dashboard_img'

interface TranslateLanguageComponentProps {
  onBack: (data: any) => void; // Explicitly define the type for onBack
  botId: string;
}

interface Language {
  name: string;
  prefix: string;
}

const TranslateLanguageComponent: React.FC<TranslateLanguageComponentProps> = ({ onBack, botId }) => {
  const [autoDetectLanguage, setAutoDetectLanguage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [languages, setLanguages] = useState<{ name: string; prefix: string }[]>([]);
  const [filteredLanguages, setFilteredLanguages] = useState<{ name: string; prefix: string }[]>([]);
  let SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>(null);

  const uid = localStorage.getItem("uid") || "";

  // useEffect(()=>{
  //   const storedPrefix = localStorage.getItem(uid + "|language");
  //   if (storedPrefix) {
  //     const storedLanguage = languageList.response.find(
  //       (lang: any) => lang.prefix === storedPrefix
  //     );
  //     if (storedLanguage) {
  //       setSelectedLanguage(storedLanguage);
  //     }
  //   }
  // })
  useEffect(() => {
    const storedStatus = localStorage.getItem(uid + "|auto_detect_language") === 'true';
    setAutoDetectLanguage(storedStatus);
    const fetchLanguages = async () => {
      try {
        const response = await fetch(`${SOCKET_SERVER_URL}/get-all-assistant-languages`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bot_key: botId
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Log the data to see its structure
        console.log("Fetched data:", data);

        // Check if data is an array or if it contains an array under a specific key
        let languageList: { name: string; prefix: string }[] = [];

        if (Array.isArray(data.response)) {
          languageList = data.response.map((lang: any) => ({
            name: lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            prefix: lang.prefix // Assuming you have flag images named like 'ar-flag.png', 'zh-flag.png', etc.
          }));
        } else if (data && Array.isArray(data)) {
          languageList = data.map((lang: any) => ({
            name: lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            prefix: lang.prefix // Assuming you have flag images named like 'ar-flag.png', 'zh-flag.png', etc.
          }));
        } else {
          throw new Error("Invalid data format");
        }

        setLanguages(languageList);
        setFilteredLanguages(languageList);

        const storedPrefix = localStorage.getItem(uid + "|language");
        if (storedPrefix) {
          const storedLanguage = data.response.find(
            (lang: any) => lang.prefix === storedPrefix
          );
          if (storedLanguage) {
            setSelectedLanguage(storedLanguage);
          }
        }
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, [botId]);

  const handleSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredLanguages(
      languages.filter((lang) =>
        lang.name.toLowerCase().includes(query)
      )
    );
  };

  const handleAutoDetectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setAutoDetectLanguage(newValue);
    localStorage.setItem(uid + "|auto_detect_language", JSON.stringify(newValue))
  };

  const handleLanguageSelect = (language: Language) => {
    setSelectedLanguage(language);
    localStorage.setItem(uid + "|language", language.prefix);
    setTimeout(() => {
      onBack("close");
    }, 300);
  };

  const handleClose = () => {
    onBack("back");

  };

  return (
    <div className="translate-language-modal">
      <div className="translate_heding">
        <button className="back-btn" onClick={handleClose}>
          <div>
            <ChevronLeft className="back-icon" />
          </div>
        </button>
        <h3 className="title_head">My Assistant Language</h3>
      </div>
      <div className="assistant-language-section">
        <div className="search-container">
          <div className="set_language">
            <div className="assistant_head">
              <img src={`${PUBLIC_PATH}/set_response_img.svg`} alt="#" />
              <span>Assistant Response Language</span>
            </div>
            {/* <button className="back-btn">
              <HiOutlinePlus />
            </button> */}
          </div>
          <div className="conv_searchbar">
            <input
              type="text"
              placeholder="Search Language"
              value={searchQuery}
              onChange={handleSearch}
            />
            <button>
              <div>
                <Search size={16} />
              </div>
            </button>
          </div>
          {/* <button className="back-btn" onClick={onBack}>
            <HiOutlinePlus />
          </button> */}
        </div>
        {/* <div className="conv_searchbar">
          <input
            type="text"
            placeholder="Search Language"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button>
            <FaMagnifyingGlass size={14} />
          </button>
        </div> */}
        <ul className="language-list">
          {filteredLanguages.map((language) => (
            <li
              key={language.prefix}
              className={`language-item ${selectedLanguage?.prefix === language.prefix ? 'selected' : ''
                }`}
              onClick={() => handleLanguageSelect(language)}
            >
              {/* <img src={language.flag} className="language-flag" alt="" /> */}
              <p>{language.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TranslateLanguageComponent;