import React from "react";
import { RotateCw, Download } from 'lucide-react';


// Define an interface for your component props
interface ChatHeaderProps {
  headerLogoIsEnable: string; // or boolean if that's more appropriate
  headerImage: string;
  headerTextColor: string;
  headerName: string;
  headerBgColor: string; // background color for header-content
  handleReloadClick: () => void;
  isSpinning: boolean;
  onDownloadClick: () => void;
  HumanAgentIconFlag: string;
  handleAgentButtonClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  HumanAgentSocket: any; // You can replace 'any' with a more specific type if available
  PUBLIC_PATH: string;
  uid: string;
  IsReloadChat: string;
  IsDownloadChat: string;
  closeHamburgerMenu: () => void; // Function passed from parent to close the hamburger menu
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  headerLogoIsEnable,
  headerImage,
  headerTextColor,
  headerName,
  headerBgColor,
  handleReloadClick,
  isSpinning,
  onDownloadClick,
  HumanAgentIconFlag,
  handleAgentButtonClick,
  HumanAgentSocket,
  PUBLIC_PATH,
  uid,
  IsReloadChat,
  IsDownloadChat,
  closeHamburgerMenu,
}) => {
  return (
    <div className="chat-header">
      <div className="header-content" style={{ background: headerBgColor }}>
        <div className="bot_header_main">
          {headerLogoIsEnable === "true" && (
            <div className="logo_main">
              <img src={headerImage} alt="NexOps AI" className="w-8 h-8" />
            </div>
          )}
          <span className="tuva_text" style={{ color: headerTextColor }}>
            {headerName}
          </span>
        </div>
        <div className="tuva_box_close">
          {IsReloadChat === "true" && (
            <button onClick={handleReloadClick} className="icon-button" title="Reload Chat">
              <RotateCw
                size={20}
                className={`reload-icon ${isSpinning ? "spinning" : ""}`}
                aria-hidden="true"
              />
            </button>)
          }
          {IsReloadChat === "true" && (
            <div className="button_disabled"
              title={!localStorage.getItem(`${localStorage.getItem('uid')}|unique_chat_session_ids`) ? "No Chat History Available" : "Download Chat"} // Set title on the wrapper
            >
              <button
                onClick={() => {
                  onDownloadClick();
                  closeHamburgerMenu
                }}
                title="Download Chat"
                className={`icon-button ${!localStorage.getItem(`${localStorage.getItem('uid')}|unique_chat_session_ids`) ? 'disabled' : ''}`} // Add 'disabled' class if allChats is empty
                disabled={!localStorage.getItem(`${localStorage.getItem('uid')}|unique_chat_session_ids`)} // Disable the button if allChats is empty
              >
                <div>
                  <Download size={20} />
                </div>
              </button>
            </div>)
          }
          {HumanAgentIconFlag === "true" && (
            <button
              onClick={(event) => {
                handleAgentButtonClick(event);
                closeHamburgerMenu();
              }}
            >
              {!HumanAgentSocket && (
                <img
                  src={`${PUBLIC_PATH}/human_chat_img.svg`}
                  alt="Human Agent"
                  title="Human Agent"
                />
              )}
              {HumanAgentSocket && (
                <img
                  src={`${PUBLIC_PATH}/human_agent_disconnect.svg`}
                  alt="Disconnect Human Agent"
                  title="Disconnect Human Agent"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
