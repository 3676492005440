import React, { useEffect, useRef } from 'react';
import { ReactTyped } from 'react-typed';
import parse from 'html-react-parser';
interface ChatMessageProps {
    message: {
        sent_by: number;
        message_type: string;
        text: string;
        fileUrl?: string;
        buttons?: any;
        message_no?: any;
    };
    ISavatarText: string;
    headerBgColor: string;
    headerTextColor: string;
    avatarImage: string;
    leftMessageBgColor: string;
    leftMessageTextColor: string;
    rightMessageBgColor: string;
    rightMessageTextColor: string;
    handleButtonClick: (value: string, index: number, message: any) => void;
    selectedButtonIndex: Record<number, { selected: boolean; selectedIndex: number }>;
    index: number;
    defaultMessages: any[];
    isNewBotMessage: boolean;
    viewlessrRef: React.RefObject<HTMLDivElement>;
    scrollToBottom: () => void;
    timer: any;
    timerElementRef: any
}
const PUBLIC_PATH = 'https://demo.turabit.com/dashboard_img'

const ChatMessage: React.FC<ChatMessageProps> = ({
    message,
    ISavatarText,
    headerBgColor,
    headerTextColor,
    avatarImage,
    leftMessageBgColor,
    leftMessageTextColor,
    rightMessageBgColor,
    rightMessageTextColor,
    handleButtonClick,
    selectedButtonIndex,
    index,
    defaultMessages,
    isNewBotMessage,
    viewlessrRef,
    scrollToBottom,
    timer,
    timerElementRef
}) => {
    const messageContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Ensure the ref exists
        if (messageContainerRef.current) {
            // Create a ResizeObserver to detect changes in height
            const resizeObserver = new ResizeObserver(() => {
                scrollToBottom();
            });
            resizeObserver.observe(messageContainerRef.current);

            // Clean up the observer when the component unmounts
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [scrollToBottom]);
    return (
        <div className="chat_message_inner">
            {timer && message.message_type === "timer" && (
                <div className="time_connecting_wrp">
                    <div className="day-container" id="day_container">
                        <div className="left-line"></div>
                        <span className="timer_wrp">
                            <span
                                className="connect_text"
                                id="second"
                                ref={timerElementRef} // Attach ref to the DOM element
                            >
                                00:00
                            </span>
                        </span>
                        <div className="right-line"></div>
                    </div>
                    <div className="day-container connecting_wrp" id="connecting">
                        <span className="day connect_text" id="currentdate">Connecting...</span>
                    </div>
                </div>
            )}
            {message.message_type === "system_message" && message.sent_by === 0 && message.text !== "" ? (
                <div className="chat-message-container">
                    <div className="left-line"></div>
                    <div className="chat-user-message">
                        <img src={`${PUBLIC_PATH}/join_human_img.svg`} alt="#" />
                        <p title={message.text}>{message.text}</p>
                    </div>
                    <div className="right-line"></div>
                </div>
            ) : null}

            {message.sent_by === 1 && (
                <div className="left_bot_main">
                    <div className="left_bot_message">
                        {message.message_type !== "button_history" && message.text !== "" && (
                            <div className="botmsg_right_inner">
                                {ISavatarText === "true" ? (
                                    <span
                                        className="assistant_bot_icon"
                                        style={{
                                            background: headerBgColor,
                                            color: headerTextColor,
                                        }}
                                    >
                                        {avatarImage}
                                    </span>
                                ) : (
                                    <img src={avatarImage} alt="Chat" />
                                )}
                            </div>
                        )}
                        <div className="about_main">
                            {/* File Link Message */}
                            {message.message_type === "file_link" ? (
                                <div className="message_left_bubble received">
                                    <span
                                        className="message_text"
                                        style={{
                                            backgroundColor: leftMessageBgColor,
                                            color: leftMessageTextColor,
                                        }}
                                    >
                                        <a
                                            href={message.fileUrl}
                                            rel="noopener noreferrer"
                                            style={{
                                                textDecoration: "underline",
                                                color: leftMessageTextColor,
                                            }}
                                        >
                                            {message.text || "Download File"}
                                        </a>
                                    </span>

                                    <svg className="chat-tail" viewBox="0 0 14 21" style={{ filter: "drop-shadow(rgba(1, 1, 0, 0.4) 3px 0px 6px)" }}>
                                        <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                        </path>
                                    </svg>
                                    <svg className="chat-tail1" viewBox="0 0 14 21" >
                                        <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                        </path>
                                    </svg>
                                    {/* <div
                                        className="tail_inner"
                                        style={{
                                            backgroundColor: leftMessageBgColor,
                                            color: leftMessageTextColor,
                                        }}
                                    ></div>
                                    <div
                                        className="tail1_inner"
                                        style={{
                                            backgroundColor: leftMessageBgColor,
                                            color: leftMessageTextColor,
                                        }}
                                    ></div>
                                    <div className="tail2"></div> */}
                                </div>
                            ) : (message.message_type === "text_message" || message.message_type === "message") &&
                                message.text != "" ? (index === defaultMessages.length - 1 &&
                                    isNewBotMessage ? (
                                    <div className="message_left_bubble received" ref={messageContainerRef}>
                                        <ReactTyped
                                            className="message_text"
                                            strings={[message.text]}
                                            typeSpeed={6}
                                            loop={false}
                                            showCursor={false}
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                            smartBackspace={true}
                                        />

                                        <svg className="chat-tail" viewBox="0 0 14 21" style={{ filter: "drop-shadow(rgba(1, 1, 0, 0.4) 3px 0px 6px)" }}>
                                            <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                            </path>
                                        </svg>
                                        <svg className="chat-tail1" viewBox="0 0 14 21" >
                                            <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                            </path>
                                        </svg>


                                        {/* <div
                                            className="tail"
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                        ></div>
                                        <div
                                            className="tail1"
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                        ></div>
                                        <div className="tail2"></div> */}
                                    </div>
                                ) : (
                                    <div ref={viewlessrRef} className="message_left_bubble received">
                                        <span
                                            className="message_text parsed-content"
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                        >
                                            {parse(`${message.text}`)}
                                        </span>

                                        <svg className="chat-tail" width="14" height="21" viewBox="0 0 14 21" style={{ filter: "drop-shadow(rgba(1, 1, 0, 0.4) 3px 0px 6px)" }}>
                                            <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                            </path>
                                        </svg>
                                        <svg className="chat-tail1" width="14" height="21" viewBox="0 0 14 21" >
                                            <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                                            </path>
                                        </svg>

                                        {/* <div
                                            className="tail"
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                        ></div>
                                        <div
                                            className="tail1"
                                            style={{
                                                backgroundColor: leftMessageBgColor,
                                                color: leftMessageTextColor,
                                            }}
                                        ></div>
                                        <div className="tail2"></div> */}
                                    </div>
                                )
                            ) : message.message_type === "button_history" && (
                                <div className="button-container">
                                    <div className="button-list">
                                        {message?.buttons.map((button: any, idx: number) => (
                                            <button
                                                key={idx}
                                                title={button.title}
                                                onClick={() => handleButtonClick(button.value, idx, message)}
                                                style={{
                                                    border: `1px solid ${headerBgColor}`,
                                                    background: selectedButtonIndex?.[message.message_no]?.selected &&
                                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === idx
                                                        ? headerBgColor
                                                        : `linear-gradient(white, white), ${headerBgColor}`,
                                                    backgroundOrigin: 'border-box',
                                                    backgroundClip: 'padding-box, border-box',
                                                    color: selectedButtonIndex?.[message.message_no]?.selected &&
                                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === idx
                                                        ? '#FFF'
                                                        : headerBgColor,
                                                    transition: 'all 0.3s ease',
                                                }}
                                                className={`option-button 
                        ${selectedButtonIndex?.[message.message_no]?.selected &&
                                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === idx
                                                        ? "selected" : ""}`}
                                            >
                                                {button.title}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {message.sent_by === 0 ? (
                <div className="right_bot_main">
                    {message.message_type === "createAdForm" && (
                        <div className="submitted_human_main">
                            <div className="human_agent_detail">
                                <div className="submitted_heading">
                                    <h2>Submitted Details</h2>
                                    <table>
                                        <tbody>
                                            {Object.entries(message.text || {}).map(([key, value]) => {
                                                const field = value as { title?: string; value?: string };
                                                return (
                                                    <tr className="title_row" key={key}>
                                                        <td className="first_col">
                                                            <b>{field?.title || key}</b>
                                                        </td>
                                                        <td><b>:</b></td>
                                                        <td className="sec_col">
                                                            {typeof field?.value === "string" && field?.value !== ""
                                                                ? field.value
                                                                : "N/A"}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    {message.message_type === "file_link" && (
                        <div className="right_message_wrp">
                            <div className="message_right_bubble received">
                                <span className="right_bot_message"
                                    style={{
                                        backgroundColor: rightMessageBgColor,
                                        color: rightMessageTextColor,
                                    }}
                                >
                                    <a
                                        href={message.fileUrl}
                                        rel="noopener noreferrer"
                                        style={{
                                            textDecoration: "underline",
                                            color: rightMessageTextColor,
                                        }}
                                    >
                                        {message.text || "Download File"}
                                    </a>
                                </span>

                                <svg className="chat-tail" viewBox="0 0 14 21" style={{ filter: "drop-shadow(9px 1px 6px rgba(0, 0, 0, 0.25))" }}>
                                    <path d="M14 5.39C7.95 6.83 1.21 13.18 0 20.75V0.129997C4.95 -0.610003 9.88 1.91 14 5.39Z" fill={rightMessageBgColor} />
                                </svg>
                                <svg className="chat-tail1" viewBox="0 0 14 21" style={{ filter: "drop-shadow(9px 1px 6px rgba(0, 0, 0, 0.25))" }}>
                                    <path d="M14 5.39C7.95 6.83 1.21 13.18 0 20.75V0.129997C4.95 -0.610003 9.88 1.91 14 5.39Z" fill={rightMessageBgColor} />
                                </svg>

                                {/* <div className="tail_inner" style={{
                                    backgroundColor: rightMessageBgColor,
                                    color: rightMessageTextColor,
                                }}></div>
                                <div className="tail1_inner" style={{
                                    backgroundColor: rightMessageBgColor,
                                    color: rightMessageTextColor,
                                }}></div>
                                <div className="tail2"></div> */}
                            </div>
                        </div>
                    )}
                    {(message.message_type === "text_message" || message.message_type === "message") &&
                        <div className="right_message_wrp">
                            <div className="message_right_bubble received">
                                <span className="right_bot_message"
                                    style={{
                                        backgroundColor: rightMessageBgColor,
                                        color: rightMessageTextColor,
                                    }}
                                >
                                    {message.text}
                                </span>

                                <svg className="chat-tail" viewBox="0 0 14 21" style={{ filter: "drop-shadow(9px 1px 9px rgba(0, 0, 0, 0.25))" }}>
                                    <path d="M14 5.39C7.95 6.83 1.21 13.18 0 20.75V0.129997C4.95 -0.610003 9.88 1.91 14 5.39Z" fill={rightMessageBgColor} />
                                </svg>
                                {/* <svg className="chat-tail1" viewBox="0 0 14 21" style={{ filter: "drop-shadow(9px 1px 9px rgba(0, 0, 0, 0.25))" }}>
                                    <path d="M14 5.39C7.95 6.83 1.21 13.18 0 20.75V0.129997C4.95 -0.610003 9.88 1.91 14 5.39Z" fill={rightMessageBgColor} />
                                </svg> */}

                                {/* <div className="tail_inner" style={{
                                    backgroundColor: rightMessageBgColor,
                                    color: rightMessageTextColor,
                                }}></div>
                                <div className="tail1_inner" style={{
                                    backgroundColor: rightMessageBgColor,
                                    color: rightMessageTextColor,
                                }}></div>
                                <div className="tail2"></div> */}
                            </div>
                        </div>
                    }
                </div>
            ) : null}

            {message.buttons && message.buttons.length > 0 && message.message_type !== "button_history" && (
                <div className="button-container">
                    <div className="button-list">
                        {message.buttons.map((button: { type: string; title: string; value: string }, buttonIndex: number) => (
                            <button
                                key={buttonIndex}
                                style={{
                                    border: `1px solid ${headerBgColor}`,
                                    background: selectedButtonIndex?.[message.message_no]?.selected &&
                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === buttonIndex
                                        ? headerBgColor // Apply solid color for selected button
                                        : `linear-gradient(white, white), ${headerBgColor}`, // Default gradient
                                    backgroundOrigin: 'border-box',
                                    backgroundClip: 'padding-box, border-box',
                                    color: selectedButtonIndex?.[message.message_no]?.selected &&
                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === buttonIndex
                                        ? '#FFF' // White text for selected button
                                        : headerBgColor, // Default text color
                                    transition: 'all 0.3s ease', // Smooth transition
                                }}
                                onClick={() => handleButtonClick(button.value, buttonIndex, message)}
                                onMouseEnter={(e) => {
                                    if (!selectedButtonIndex?.[message.message_no]?.selected) {
                                        e.currentTarget.style.background = headerBgColor;
                                        e.currentTarget.style.color = '#FFF';
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    if (!selectedButtonIndex?.[message.message_no]?.selected) {
                                        e.currentTarget.style.background = `linear-gradient(white, white), ${headerBgColor}`;
                                        e.currentTarget.style.color = headerBgColor;
                                    }
                                }}
                                title={button.title} // Tooltip for full text
                                className={`option-button 
                              ${selectedButtonIndex?.[message.message_no]?.selected &&
                                        selectedButtonIndex?.[message.message_no]?.selectedIndex === buttonIndex
                                        ? "selected" : ""}`}
                            >
                                {button.title}
                            </button>
                        ))}
                    </div>
                </div>
            )}

        </div>
    );
};
export default ChatMessage;
