import React, { useEffect } from 'react';
import Chatbot from './components/Chatbot';

function App() {
  useEffect(() => {
    // Create the <link> element dynamically
    const link = document.createElement('link');
    link.href = "https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap";
    link.rel = "stylesheet";
    link.type = "text/css";
    
    // Append the link tag to the document head
    document.head.appendChild(link);

    // Cleanup function to remove the link when the component is unmounted
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  // const botId = id ? id.getAttribute('data-bot-id') : '';
    // let botId = 'xtomrwbakmknmxkp';
    let id = document.getElementById('root');
   let botId = id ? id.getAttribute('data-bot-id') : 'dev_gnlplddgfrtkjgbw';
    // let botId = 'stage_gkbycdlrwywgwvja';
    let Uid = localStorage.getItem('uid');
    if (!Uid) {
     let uid = btoa(Math.random().toString()).replace(/=|\+|\//g, '');
      localStorage.setItem('uid', uid);
    }  return (
    <div className="App">
      {/* Pass the botId to the Chatbot component */}
      <Chatbot botId={botId || ''} />
    </div>
  );



}


export default App;
