const SplashScreen = ({ splashImage }: { splashImage: any }) => {
    return (
      <div className="splash-screen-inner">
        <div className="splash-screen">
          <div className="welcome_msg_main">
            <div className="welcome_tuva">
              <div className="seeking_text">
                {splashImage ? (
                  <img src={splashImage} alt="Tuva AI Logo" />
                ) : (
                  <div>No image available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default SplashScreen;
  