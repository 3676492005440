import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import "./ChatHistory.css"; // Ensure styles are applied
import { ChevronLeft  , Download } from 'lucide-react';
const PUBLIC_PATH = 'https://demo.turabit.com/dashboard_img'

interface ChatHistoryProps {
  onClose: () => void;
  botId: string;
}
interface ChatSession {
  unique_chat_session_id: string;
  datetime: string;
}
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;
const ChatHistory = forwardRef(({ onClose, botId }: ChatHistoryProps, ref) => {
  const [chatSessions, setChatSessions] = useState<ChatSession[]>([]);
  const [noData, setNoData] = useState(false);
  const [translatedGreet, setTranslatedGreet] = useState<string>("");
  const datetime = new Date();
  const uid = localStorage.getItem("uid") || ""; // User ID from localStorage

  useEffect(() => {
    const allChats = localStorage.getItem(`${uid}|unique_chat_session_ids`);
    if (allChats) {
      const parsedChats = JSON.parse(allChats);
      setChatSessions(parsedChats.values || []);
    } else {
      setNoData(true);
    }
  }, [uid]);

  function formatDate(dateTimeString: any) {
    const [datePart, timePart] = dateTimeString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Create and return the formatted Date string
    const d = new Date(year, month - 1, day, hours, minutes, seconds);

    // Ensure two-digit format
    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}`;
  }

  useEffect(() => {
    const fetchTranslation = async () => {
      const currentIp = await getUserIP(); // Fetch user's IP dynamically
      const currentHour = new Date().getHours();

      let greetingMessage = "Good Evening";
      if (currentHour >= 5 && currentHour < 12) {
        greetingMessage = "Good Morning";
      } else if (currentHour >= 12 && currentHour < 17) {
        greetingMessage = "Good Afternoon";
      }

      const messageData = { greet_msg: greetingMessage };
      const translatedMessage = await translateMessageLanguage(messageData, botId, uid, currentIp);
      setTranslatedGreet(translatedMessage.greet_msg || greetingMessage);
    };

    fetchTranslation();
  }, [botId, uid]);

  const getUserIP = async (): Promise<string> => {
    try {
      const response = await fetch("https://api.db-ip.com/v2/free/self");
      const data = await response.json();
      return data.ipAddress || "Unknown IP";
    } catch (error) {
      console.error("Error fetching user IP:", error);
      return "Unknown IP";
    }
  };

  const translateMessageLanguage = async (messageData: { [key: string]: string }, botId: string, uid: string, ip: string) => {
    const translatorUrl = `${SOCKET_SERVER_URL}/translate-message`; // Replace with your actual TCC URL
    const data = {
      uid,
      bot_id: botId,
      current_ip: ip,
      ...messageData,
    };

    try {
      const response = await fetch(translatorUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const translatedMessage = await response.json();
        return translatedMessage; // Assuming the API returns the translated data directly
      } else {
        console.error("Translation API error:", response.statusText);
        return messageData; // Fallback to the original message if translation fails
      }
    } catch (error) {
      console.error("Error during translation:", error);
      return messageData; // Fallback to the original message if an error occurs
    }
  };

  const handleDownload = async (sessionId: string) => {
    const locationResponse = await fetch("https://api.db-ip.com/v2/free/self");
    const locationData = await locationResponse.json();

    // Format the location data to match the required structure
    const formattedLocationData = {
      ipAddress: locationData.ipAddress,
      continentCode: locationData.continentCode,
      continentName: locationData.continentName,
      countryCode: locationData.countryCode,
      countryName: locationData.countryName,
      stateProv: locationData.stateProv,
      city: locationData.city,
    };

    // Stringify the formatted location data
    const userLocationData = JSON.stringify(formattedLocationData, null, 2);
    const currentHour = new Date().getHours();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let greetingMessage = "Good Evening";
    if (currentHour >= 5 && currentHour < 12) {
      greetingMessage = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      greetingMessage = "Good Afternoon";
    }

    const visitorChatData = {
      uid,
      bot_id: botId,
      user_location_data: userLocationData,
      username: localStorage.getItem("user_full_name") || "",
      greet: translatedGreet || greetingMessage,
      timezone: timeZone,
      webchat_gui_url: window.location.origin,
      user_info: { ...localStorage },
      download_chat_history: true,
      report_chat_history: false,
      unique_chat_session_id: sessionId,
    };

    try {
      const response = await fetch(`${SOCKET_SERVER_URL}/download-chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorChatData),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.report_to !== "admin") {
          const link = document.createElement("a");
          const dataURI = "data:text/html," + encodeURIComponent(data.chat_history_html);
          link.download = data.file_name;
          link.href = dataURI;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          const message =
            data.status === 200
              ? "I have sent chat history to your IT Support Team."
              : "Failed to send chat history to your IT Support Team. Kindly try again.";
          alert(message);
        }
      } else {
        console.error("Failed to fetch chat history:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading chat history:", error);
    }
  };
  useImperativeHandle(ref, () => ({
    handleDownload,
  }));
  return (
    <div className="chat-history-container">
      <div className="chat-history-header">
        <button className="back-button" onClick={onClose}>
          <div>
          <ChevronLeft className="back-icon" />
          </div>
        </button>
        <h2>My Chat History</h2>
      </div>
      <div className="chat-history-list">
        {noData ? (
          <div className="no-data-found">
            <h3>No Chat Found</h3>
          </div>
        ) : (
          chatSessions.map((chat) => (
            <div key={chat.unique_chat_session_id} className="chat-history-item">
              <div className="history-info">
                <img src={`${PUBLIC_PATH}/user_chat_history_img.svg`} alt="#" />
                <span className="history-date">{formatDate(chat.datetime)}</span>
              </div>
              <div>
              <Download
                className="download_history"
                size={16}
                onClick={() => handleDownload(chat.unique_chat_session_id)}
              />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
});

export default ChatHistory;