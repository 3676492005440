import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Send, X } from 'lucide-react';
import io, { Socket } from 'socket.io-client';
import '../index.css'
import './chatbot.css'
import axios from 'axios';
import parse from 'html-react-parser';
import FeedbackForm from './FeedBackForm/FeedBackForm';
import ChatHistory from './ChatHistory/ChatHistory';
import ChatMessage from './ChatMessages/ChatMessage'
import TranslateLanguageComponent from './Translate_Language/Translate_language';
import CreateAdUser from './CreateAdUser/CreateAdUser';
import SplashScreen from './SplashScreen/SplashScreen';
import ChatSkeleton from './skeletonLoader/skeletonLoader';
import ChatHeader from './ChatHeader/ChatHeader';
import ProgressBar from './ProgressBar/ProgressBar';
const PUBLIC_PATH = 'https://demo.turabit.com/dashboard_img'
// console.log(window.devicePixelRatio)
let socket_url = process.env.REACT_APP_SOCKET_SERVER_URL
interface MenuOption {
  href: string;
  icon: string;
  name: string;
  func_name: string;
  explanation: string;
}

interface Props {
  botId: string;
}

declare global {
  var runningTimer: NodeJS.Timeout | null;
}

interface FormInput {
  id: string;
  snap: any;
  title: string;
  type: string;
  value: string;
  required: string;
  validation_pattern: string;
  validation_error: string;
}

interface FormButton {
  id: string;
  title: string;
}

interface FormElement {
  INPUT?: FormInput[];
  BUTTON?: FormButton[];
  SELECT?: FormSelect[];
  CHECKBOX?: FORMCHECKBOX[];

}

interface FORMCHECKBOX {
  id: string;
  title: string;
  value: string;
  OPTIONS: SelectCheckboxOption[];
}
interface FormSelect {
  id: string;
  title: string;
  value: string;
  OPTIONS: SelectOption[];
}

interface SelectOption {
  value: string;
  display_name: string;
}

interface SelectCheckboxOption {
  value: string;
}

interface FormJson {
  TITLE_VALUE: string;
  FORM_ID?: string,
  FORM_ELEMENTS: FormElement[];
}

var messageData;
const ChatbotInterface: React.FC<Props> = ({ botId }) => {
  const chatHistoryRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(document.createElement('input'));
  const [uid, setUid] = useState(() => {
    return localStorage.getItem('uid') || btoa(Math.random().toString()).replace(/=|\+|\//g, '');
  });
  const humanAgentSocketRef = useRef<Socket | null>(null);
  const fileRef = useRef<File | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [showProgress, setShowProgress] = useState(false)
  const [lastClickedMessageNo, setLastClickedMessageNo] = useState<number | null>(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState<Record<number, { selected: boolean; selectedIndex: number }>>({});
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showTranslateModal, setShowTranslateModal] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [SubmittedModal, setSubmittedModal] = useState(false);
  const [error, setError] = useState('');
  const [HumanAgentSocket, setHumanAgentSocket] = useState(false);
  const [shouldAutoDisconnect, setshouldAutoDisconnect] = useState(false);
  const [socket, setSocket] = useState<any>(null);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [Isloading, setIsloading] = useState(false);
  const [chatBgImage, setchatBgImage] = useState<any>(null);
  const [headerBgColor, setheaderBgColor] = useState<any>('unset');
  const [headerImage, setheaderImage] = useState<any>(null);
  const [headerLogoIsEnable, setheaderLogoIsEnable] = useState<any>(null);
  const [headerName, setheaderName] = useState<any>(null);
  const [splashImage, setsplashImage] = useState<any>('unset');
  const [avatarImage, setavatarImage] = useState<any>(null);
  const [ISavatarText, setISavatarText] = useState<any>(null);
  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);
  const [powerdByText, setsetpowerdByText] = useState<any[]>([]);
  const [powerdByLogo, setsetpowerdByLogo] = useState<string>();
  const [powerbyTextColor, setpowerbyTextColor] = useState<string>();
  const [leftMessageBgColor, setleftMessageBgColor] = useState<any>(null);
  const [leftMessageTextColor, setleftMessageTextColor] = useState<any>(null);
  const [rightMessageBgColor, setrightMessageBgColor] = useState<any>(null);
  const [rightMessageTextColor, setrightMessageTextColor] = useState<any>(null);
  const [widgetMessageColor, setwidgetMessageColor] = useState<any>(null);
  const [validationRules, setValidationRules] = useState(null);
  const [createForm, setCreateForm] = useState<FormJson | null>(null);
  const [fileUploadUserFormJson, setfileUploadUserFormJson] = useState<FormJson | null>(null);
  const [widgetMessageBgColor, setwidgetMessageBgColor] = useState<any>(null);
  const [MessageBorderColor, setMessageBorderColor] = useState<any>(null);
  const [widgetIconbgcolor, setwidgetIconbgcolor] = useState<any>(null);
  const [IsReloadChat, setIsReloadChat] = useState<any>(null);
  const [IsDownloadChat, setIsDownloadChat] = useState<any>(null);
  const [headerTextColor, setheaderTextColor] = useState<any>(null);
  const [HumanAgentIconFlag, setHumanAgentIconFlag] = useState<any>('false');
  const [widgetMessage, setwidgetMessage] = useState<any>(null);
  const [widgetMessageFlag, setwidgetMessageFlag] = useState<any>(null);
  const [widgetIcon, setwidgetIcon] = useState<any>(null);
  const [isNewBotMessage, setIsNewBotMessage] = useState(false);
  const [defaultMessages, setDefaultMessages] = useState<any[]>([]);
  const [Ishamburger, setIshamburger] = useState<any>(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [timer, setTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isTypingRef = useRef<boolean>(false);
  const elapsedTimeRef = useRef(0); // Store elapsed time
  const timerWrapperRef = useRef<HTMLDivElement>(null);
  const timerIdRef = useRef<NodeJS.Timeout | null>(null); // Timer reference
  const timerElementRef = useRef<HTMLSpanElement | null>(null); // DOM reference for the timer
  const progressRef = useRef(0);
  const formRef = useRef<HTMLDivElement | null>(null);
  const hasScrolledRef = useRef(false);
  const viewlessrRef = useRef<HTMLDivElement>(null);
  const [showSplash, setShowSplash] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  useEffect(() => {
    if (showHamburgerMenu && !animationPlayed) {
      setTimeout(() => setAnimationPlayed(true), 500); // Delay update until animation finishes
    }
    else {
      setAnimationPlayed(false)
    }
  }, [showHamburgerMenu]);
  useEffect(() => {
    if (createForm && formRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting && !hasScrolledRef.current) {
          formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
          hasScrolledRef.current = true;
        }
      }, { threshold: 0.1 });

      observer.observe(formRef.current);
      return () => observer.disconnect();
    }
  }, [createForm]);

  useEffect(() => {
    chatContainerRef.current?.scrollIntoView();
  }, [defaultMessages, selectedButtonIndex, isTyping, showProgress, isOpen, shouldRender, showHamburgerMenu, error, isNewBotMessage, createForm, validationRules, showFeedbackForm, fileUploadUserFormJson, showChatHistory, successMessage, showTranslateModal, SubmittedModal]);

  useEffect(() => {
    defaultMessages.forEach(({ message_no }) => {
      if (message_no && selectedButtonIndex?.[message_no] === undefined) {
        setSelectedButtonIndex((prev: any) => ({
          ...prev,
          [message_no]: { selected: false, selectedIndex: -1 }
        }));
      }
    });
  }, [defaultMessages, selectedButtonIndex]);

  useEffect(() => {
    if (timer && timerWrapperRef.current) {
      setTimeout(() => timerWrapperRef.current?.classList.add("visible"), 100);
    }
  }, [timer]);

  useEffect(() => {
    if (localStorage.getItem("IsBotOpen") === "true") {
      if (localStorage.getItem("splashScreenIsenable") === "true") {
        setShowSplash(localStorage.getItem("splashShown") !== "true");
      }
      setIsOpen(true);
      setShouldRender(true);
    }

    setDefaultMessages([]);
    getIp();
    setShowProgress(false);
    setIsTyping(false);
    localStorage.setItem(`${uid}|auto_detect_language`, JSON.stringify(true));

    fetchChatData(); // Ensuring it is called when botId changes

    return () => {
      timerIdRef.current && clearInterval(timerIdRef.current);
      humanAgentSocketRef.current?.close();
      humanAgentSocketRef.current = null;
      sessionStorage.setItem("geo_access", sessionStorage.getItem("geo_access") ?? "denied");
    };
  }, [botId]); // Add botId to the dependency array

  const doReset = (newUid?: string) => {
    if (!localStorage.getItem('uid') || newUid) {
      toggleChatbot('reboot');
      // Use the passed newUid or generate a new one
      const uidToUse = newUid || btoa(Math.random().toString()).replace(/=|\+|\//g, '');
      // Update localStorage
      localStorage.setItem('uid', uidToUse);
      setUid(uidToUse)
      localStorage.setItem(uidToUse + "|auto_detect_language", JSON.stringify(true));
      // Update the uid in your React state
      setUid(uidToUse); // You'll need to add this state setter
      getIp();
    }

    socket.disconnect();
    setShowProgress(false)
    setDefaultMessages([]);
    setCreateForm(null);
    fetchChatData();
  };

  const getIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const currentIp = response.data.ip;
      localStorage.setItem(`${localStorage.getItem('uid')}|ip_address`, currentIp);
      const geoAccess = sessionStorage.getItem("geo_access");
    } catch (err) {
      console.error("Error fetching IP address:", err);
    }
  };

  const handleAgentButtonClick = (event: any) => {

    if (HumanAgentSocket) {
      disconnect_to_human_agent({ flag: "disconnect" })
    }
    else {
      handleSendMessage('connect to human agent')
    }
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };


  useLayoutEffect(() => {
    if (lastClickedMessageNo !== null && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [lastClickedMessageNo, isOpen, shouldRender]);

  const handleCloseModal = (data: any) => {
    if (data === "back") {
      setShowTranslateModal(false);
    }
    else {
      setShowTranslateModal(false);
      setShowHamburgerMenu(false);
    }
  };

  const handleCloseChatHistory = () => {
    setShowChatHistory(false);
  }

  const handleCloseSubmitterModal = () => {
    setSubmittedModal(false);
  }

  const reconnectSocket = () => {
    console.log('Reconnecting original socket...');
    const newSocketInstance = initializeSocket();
    setSocket(newSocketInstance);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, input: any) => {
    const file = e.target.files?.[0];
    if (file) {
      // Reset error and previous message
      const mimeTypes: Record<string, string> = {
        ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".pdf": "application/pdf",
      };
      setError('');
      const acceptedTypes = input.accept
        .split(',')
        .map((ext: string) => mimeTypes[ext.trim()] || ext.trim());
      if (input.accept && !acceptedTypes.includes(file.type)) {
        setError('Invalid file type. Please upload a valid file.');
      }
      else if (file.size > 2 * 1024 * 1024) {
        setError('File is too large. Please upload a smaller file.');
      } else {
        fileRef.current = file; // Valid file, so store it
      }
    }
  };

  const handleDataStorage = (storage_data: any) => {
    for (const key in storage_data) {
      if (key === 'unique_chat_session_id') {
        const currentdate = new Date();
        const datetime = `${currentdate.getDate()}/${currentdate.getMonth() + 1}/${currentdate.getFullYear()} ${('0' + currentdate.getHours()).slice(-2)
          }:${('0' + currentdate.getMinutes()).slice(-2)}:${('0' + currentdate.getSeconds()).slice(-2)}`;

        try {
          const current_session_id = storage_data[key];
          let old_session_ids = localStorage.getItem(uid + '|unique_chat_session_ids');
          let final_val;
          if (old_session_ids) {
            final_val = JSON.parse(old_session_ids);
            final_val.values.push({ unique_chat_session_id: current_session_id, datetime });
          } else {
            final_val = { values: [{ unique_chat_session_id: current_session_id, datetime }] };
            old_session_ids = '';
          }

          if (!old_session_ids.includes(current_session_id)) {
            localStorage.setItem(localStorage.getItem('uid') + '|unique_chat_session_ids', JSON.stringify(final_val));
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          localStorage.setItem(key, storage_data[key]);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const initializeSocket = () => {
    const socketInstance = io(socket_url + '/socket-webchat', {
      secure: true,
      reconnection: true,
      query: { bot_id: botId, uid: localStorage.getItem('uid') },
      transports: ['websocket', 'polling'],
      reconnectionAttempts: 5,
      reconnectionDelay: 3000,
    });
    setSocket(socketInstance);
    socketInstance.on('connect', () => { });
    socketInstance.on('message', (data: any) => {
      const currentInputValue = inputRef.current?.value || '';
      if (data.message_type === 'text_message') {
        const newMessage = {
          sender: 'bot',
          message_type: 'text_message',
          text: data.text,
          sent_by: 1
        };
        setIsNewBotMessage(true);
        if (data.text !== "") {
          setDefaultMessages((prevMessages) => {
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.value = currentInputValue;
              }
            }, 0);
            return [...prevMessages, newMessage];
          });
        }
      }
      else if (data.message_type === 'button') {
        const textMessage = {
          sender: 'bot',
          message_type: 'text_message',
          text: data.attachments[0]?.content?.text || data.text, // Use text from `attachments` or fallback to `data.text`
          sent_by: 1,
        };
        const buttonMessage = {
          sender: 'bot',
          message_type: 'button',
          buttons: data.attachments[0]?.content?.buttons || [], // Buttons array
          message_no: data.message_no,
        };
        setIsNewBotMessage(false);
        setDefaultMessages(prevMessages => {
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.value = currentInputValue;
            }
          }, 0);
          // Prevent unnecessary re-renders
          const updatedMessages = [...prevMessages];
          // Only push textMessage if it has non-empty text
          if (textMessage.text.trim() !== '') {
            updatedMessages.push(textMessage);
          }
          updatedMessages.push(buttonMessage);
          return updatedMessages;
        });
      }
      else if (data.message_type === 'data_storage') {
        handleDataStorage(data.storage_data);
      }
      else if (data.message_type === 'form') {
        setIsNewBotMessage(false);
        const formJson: FormJson = {
          TITLE_VALUE: data.TITLE_VALUE,
          FORM_ID: data.form_id,
          FORM_ELEMENTS: data.FORM_ELEMENTS.map((element: any) => {
            // First, handle the INPUT block if it exists
            let result: any = {};
            if (element.INPUT) {
              result.INPUT = element.INPUT.map((input: any) => ({
                id: input.id,
                type: input.type,
                title: input.title,
                value: input.value || "",
                required: input.required || false,
                validation_error: input.validation_error || "",
                validation_pattern: input.validation_pattern || "",
                snap: input.snap || ""
              }));
            }

            if (element.SELECT) {
              result.SELECT = element.SELECT.map((select: any) => ({
                id: select.id,
                value: select.value,
                title: select.title,
                OPTIONS: select.OPTIONS.map((option: any) => ({
                  value: option.value,
                  display_name: option.display_name,
                })),
              }));
            }
            if (element.CHECKBOX) {
              result.CHECKBOX = element.CHECKBOX.map((select: any) => ({
                id: select.id,
                value: select.value,
                title: select.title,
                OPTIONS: select.OPTIONS.map((option: any) => ({
                  value: option.id,
                })),
              }));
            }
            if (element.BUTTON) {
              result.BUTTON = element.BUTTON.map((button: any) => ({
                id: button.id,
                title: button.title,
                value: button.value || "",
                function: button.function || "",
                call_back_url: button.call_back_url || "",
              }));
            }
            return result;
          }),
        };
        setCreateForm(formJson);
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.value = currentInputValue;
          }
        }, 0);
        hasScrolledRef.current = false;
      }
      else if (data.message_type === 'ping_status') {
        if (data.ping_result === "True") {
          progressRef.current = 0;

        }
      }
      else if (data.message_type === 'progress_status') {
        setShowProgress(true)
        const progressContainer = document.getElementById('progress-container');
        const progressBar = document.getElementById('progress-bar');
        const progressText = document.getElementById('progress-text');

        setIsTyping(false);
        const newProgress = parseInt(data.op_progressbar_status, 10);
        if (progressRef.current !== newProgress) {
          progressRef.current = newProgress;

          // Update progress bar width and text
          if (progressBar && progressText) {
            console.log('Updating progress bar:', newProgress); // Debugging: Log DOM update
            progressBar.style.width = `${newProgress}%`;
            progressBar.style.backgroundColor = newProgress === 100 ? '#4caf50' : '#2196f3';
            progressText.textContent = newProgress === 100 ? '100%' : `${newProgress}%`;
          }

          // Show/hide progress bar
          if (newProgress < 100) {
            setShowProgress(true); // Show progress bar
          } else {
            setTimeout(() => {
              setShowProgress(false); // Hide progress bar after 1 second
            }, 1000);
          }
        }
      }

      if (data.agent_present === true) {
        socketInstance.disconnect();
        setSocket(null);
        connectToHumanAgent();
      }

      if (data.remove_typing && data.remove_typing == true) {
        setIsTyping(false);
      }
    });

    return socketInstance;
  }

  // Only scroll when selectedButtonIndex changes
  const handleButtonClick = ((value: string, idx: number, message: any) => {
    setLastClickedMessageNo(message.message_no);
    handleSendMessage(value, true);
    setSelectedButtonIndex((prev: any) => ({
      ...prev,
      [message.message_no]: {
        selected: true,
        selectedIndex: idx
      }
    }));
  });

  const connectToHumanAgent = () => {
    // Initialize the socket connection if it doesn't already exist
    if (!humanAgentSocketRef.current) {
      const humanAgentSocket = io(
        process.env.REACT_APP_HUMAN_AGENT,
        {
          secure: true,
          reconnection: true,
          query: { bot_id: botId, uid: uid, type: "user" },
          transports: ["websocket", "polling"],
          reconnectionAttempts: 10,
          reconnectionDelay: 10000,
        }
      );

      humanAgentSocket.on("connect", () => {
        console.log("humanAgentSocket Connected");
      });

      humanAgentSocket.on("agent_typing", () => {
        Promise.resolve().then(() => {
          setIsTyping(true);
        });
      });

      humanAgentSocket.on("agent_typing_stop", () => {
        setIsTyping(false);
      });

      humanAgentSocket.on("agent_to_user_message", (data: any) => {
        if (data) {
          handleSocketMessage(data)
        }
      });

      humanAgentSocket.on("file_upload_link", (data: any) => {
        setfileUploadUserFormJson(null);
        if (data) {
          if (data.type === "files") {
            // Display the uploaded file link in the chat
            setDefaultMessages((prevMessages) => [
              ...prevMessages,
              {
                message_type: "file_link",
                text: data.user_message,
                fileUrl: data.file_url,
                sent_by: 0
              },
            ]);
          }
        }
      });

      humanAgentSocket.on("agent_disconnected", (data: any) => {
        disconnect_to_human_agent(data);
      }
      );

      humanAgentSocket.on("auto_disconnect", (msg: any) => {
        if (msg.flag === "auto_disconnect") {
          console.log("Auto Disconnected");
          const queueTime = JSON.parse(localStorage.getItem("queue_time") || "{}");
          delete queueTime[uid];
          localStorage.removeItem("queue_time");
          setshouldAutoDisconnect(true);
          disconnect_to_human_agent(msg);
        }
      });
      humanAgentSocketRef.current = humanAgentSocket;
    }
  };

  const disconnect_to_human_agent = (msg: { flag: any; agent_name?: any }) => {
    if (!humanAgentSocketRef.current) {
      console.error("No active humanAgentSocket to disconnect");
      return;
    }
    let disconnectMsg = "";
    if (msg.flag === "agent_disconnected") {
      disconnectMsg = `${msg.agent_name} left the chat`;
      const newMessage = {
        sender: 'user',
        message_type: "system_message",
        text: disconnectMsg,
        sent_by: 0
      };
      setDefaultMessages(prevMessages => {
        // Prevent unnecessary re-renders
        const updatedMessages = [...prevMessages, newMessage];
        return updatedMessages;
      });
    } else if (msg.flag === "auto_disconnect") {
      disconnectMsg =
        "I apologize for any inconvenience. Please try again later as our agents are currently unavailable to assist you.";
      stopTimer();
      const newMessage = {
        sender: 'bot',
        message_type: "text_message",
        text: disconnectMsg,
        sent_by: 1
      };
      setDefaultMessages(prevMessages => {
        // Prevent unnecessary re-renders
        const updatedMessages = [...prevMessages, newMessage];
        return updatedMessages;
      });
    } else if (msg.flag === "reboot") {
      disconnectMsg = "You are Disconnected";
    } else {
      disconnectMsg = "You are Disconnected";
      const newMessage = {
        sender: 'user',
        message_type: "system_message",
        text: disconnectMsg,
        sent_by: 0
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
    }

    humanAgentSocketRef.current.emit("user_disconnect", {
      bot_id: botId,
      user_uid: uid,
      message: disconnectMsg,
      auto_disconnect: msg.flag === "auto_disconnect",
      reboot: msg.flag === "reboot",
    });
    reconnectSocket();
    setHumanAgentSocket(false)
    humanAgentSocketRef.current.close();
    humanAgentSocketRef.current = null; // Cleanup reference
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollIntoView();
    }
  };
  const handleSocketMessage = (msg: any) => {
    if (msg.flag === "timer started") {
      setTimer(true);
      const newMessage = {
        sender: 'system_message',
        message_type: "timer",
        sent_by: 6
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsNewBotMessage(false);
      startTimer();

      // Initialize queue time in localStorage
      let queueTime: { minutes: number; seconds: number } = { minutes: 0, seconds: 0 };
      try {
        const storedQueueTime = JSON.parse(localStorage.getItem("queue_time") || "{}");
        // Ensure queueTime is a valid object with minutes and seconds
        if (typeof storedQueueTime === "object" && "minutes" in storedQueueTime && "seconds" in storedQueueTime) {
          queueTime = storedQueueTime;
        }
      } catch (error) {
        console.error("Invalid JSON in localStorage, resetting queue_time:", error);
      }
      // Update state with retrieved or default queue time
      setMinutes(queueTime.minutes || 0);
      setSeconds(queueTime.seconds || 0);
    }

    else if (msg.type === "user_msg") {
      let joinedChatMsg = `${msg.new_agent_connected} joined the chat`;
      let leftChatMsg = `${msg.user_message} left the chat`;
      const newMessage = {
        sender: 'bot',
        message_type: "system_message",
        text: joinedChatMsg,
        img: msg.agent_img_path,
        sent_by: 0
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);

      const newMessage1 = {
        sender: 'bot',
        message_type: "system_message",
        text: leftChatMsg,
        img: msg.agent_img_path,
        sent_by: 0
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage1]);

    }
    else if (msg.file_url) {
      setDefaultMessages((prevMessages) => [
        ...prevMessages,
        {
          message_type: "file_link",
          text: msg.user_message,
          fileUrl: msg.file_url,
          sent_by: 1
        },
      ]);
    }

    else if (msg.user_message && msg.mode === "user_occupied") {
      const newMessage = {
        sender: 'bot',
        message_type: "text_message",
        text: msg.user_message,
        sent_by: 1
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
    }

    else if (msg.user_message && msg.flag === "timer stopped") {
      const newMessage = {
        sender: 'bot',
        message_type: "system_message",
        text: msg.user_message,
        img: msg.agent_img_path,
        sent_by: 0
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
      const queueTime = JSON.parse(localStorage.getItem("queue_time") || "[0, 0]");
      const formattedQueueTime = formatTimeQueueTime(queueTime);

      setHumanAgentSocket(true)
      humanAgentSocketRef.current?.emit("accept_request", {
        user_uid: uid,
        type: "user",
        bot_id: botId,
        total_queue_time: formattedQueueTime,
      });
      stopTimer();
    }

    else if (msg.user_message) {
      const newMessage = {
        sender: 'bot',
        message_type: "text_message",
        text: msg.user_message,
        sent_by: 1
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);

    }

    else if (msg.form) {
      // Transform the msg.form data into a FormJson structure
      const formJson = {
        TITLE_VALUE: msg.form.TITLE_VALUE || "Please upload your file here.",
        FORM_ID: msg.form.form_id,
        FORM_ELEMENTS: msg.form.FORM_ELEMENTS.map((element: any) => {
          let result: any = {};
          if (element.BUTTON) {
            result.BUTTON = element.BUTTON.map((button: any) => ({
              id: button.id,
              title: button.title,
              value: button.value || "",
              function: button.function || "",
              call_back_url: button.call_back_url || "",
            }));
          }
          if (element.INPUT) {
            result.INPUT = element.INPUT.map((input: any) => ({
              id: input.id,
              type: input.type,
              title: input.title,
              accept: input.accept,
              snap: input.snap || "",
              required: input.required === "True",
              validation_error: input.validation_error || "",
            }));
          }
          return result;
        }),
      };
      setfileUploadUserFormJson(formJson)
    }
    else {
      stopTimer()
    }
  };
  const formatSupplierData = (data: any): string => {
    const suppliers = data.value;
    const formattedSuppliers = suppliers.map((supplier: string) => {
      return supplier.replace(/,/g, ';');
    });
    const formattedValue = formattedSuppliers.join(', ');
    return formattedValue; // Return the formatted string
  };

  const createMessageData = (
    text: string,
    file?: any,
    humanagent?: any,
    formFilled: boolean = false,
    additionalData: Record<string, any> = {}
  ) => {
    return {
      type: "message",
      id: "1234abcd",
      timestamp: Math.floor(Date.now() / 1000),
      serviceUrl: "",
      channelGuiId: "chatbot_gui",
      from: {
        id: "1234abcd",
        name: "username",
      },
      conversation: {
        id: uid,
        name: "conversation's name",
      },
      recipient: {
        id: "12345678",
        name: "TurabitSmartTech",
      },
      text: text,
      file: file,
      file_name: file?.name,
      smartbothandler: botId,
      label_text: text,
      form_filled: formFilled,
      ...additionalData, // Add any additional data if needed
    };
  };

  const handleSendMessage = (
    value: any,
    buttonselected?: any,
    humanagent?: any,
    createForm?: any,
    uploadedFileData?: any,
    clarusFileUpload?: any
  ) => {
    const isHTML = (str: string) => /<\/?[a-z][\s\S]*>/i.test(str);

    if (isHTML(value)) {
      const newMessage = {
        sender: 'bot',
        message_type: 'text_message', // or simply 'text_message' if you prefer
        text: "Sorry, I cannot process programming queries or system commands.",
        sent_by: 1,
      };
      setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
      return Promise.resolve(); // stop further processing
    }
    if (value.trim() !== '' && !HumanAgentSocket) {
      setIsTyping(true);
    }

    return new Promise<void>((resolve, reject) => {
      let messageData;

      if (uploadedFileData) {
        messageData = createMessageData(
          uploadedFileData.name,
          uploadedFileData,
          null,
          true,
          { label_text: `${uploadedFileData.name} file uploaded successfully.` }
        );

        const newMessage = {
          sender: "user",
          message_type: "file_upload",
          text: uploadedFileData.name,
          sent_by: 0,
        };

        setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
      }
      else if (createForm) {
        // Create deep clone of the form
        var duplicateForm: any = structuredClone(createForm);

        // Handle Suppliers formatting if it exists
        if (duplicateForm['Suppliers']) {
          const formattedValue = formatSupplierData(duplicateForm['Suppliers']);
          duplicateForm['Suppliers'] = {
            ...duplicateForm['Suppliers'],
            value: formattedValue,
          };
        }

        // Create backend data by processing all keys in duplicateForm
        const backendData = Object.keys(duplicateForm).reduce((acc, key) => {
          const field = duplicateForm[key];
          if (field && typeof field === 'object') {
            // Handle the value
            const value = Array.isArray(field.value)
              ? field.value.join(', ')
              : field.value || "";

            acc[key] = value;

            // Handle buttonId if present
            if (field.buttonId) {
              acc[field.buttonId] = "";
            }
          }
          return acc;
        }, {} as Record<string, any>);

        // Create message data
        messageData = createMessageData(
          JSON.stringify(backendData), null, null, true);

        // Update form handling
        var updatedForm = structuredClone(createForm);

        // Handle ou_name if it exists
        if (updatedForm.ou_name) {
          updatedForm.ou_name.value = updatedForm.ou_name.display_name;
        }

        // Process all nested objects with array values
        Object.keys(updatedForm).forEach((key) => {
          if (updatedForm[key] &&
            typeof updatedForm[key] === 'object' &&
            'value' in updatedForm[key]) {
            if (Array.isArray(updatedForm[key].value)) {
              updatedForm[key].value = updatedForm[key].value.join(', ');
            }
          }
        });

        // Create new message
        const newMessage = {
          sender: 'user',
          message_type: 'createAdForm',
          display_logo: Object.values(createForm.text || {}).some(
            (value) => (value as { title?: string })?.title?.toLowerCase() === 'location'
          ) || true,
          text: JSON.stringify(createForm) ? updatedForm : value.trim(),
          sent_by: 0,
        };

        setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
      }
      else if (value !== '' && !humanagent && !HumanAgentSocket) {
        let cookieStat = getCookie(botId);
        if (!cookieStat) {
          setCookie(botId, uid, 1);
        } else {
          socket.id = cookieStat;
        }

        if (clarusFileUpload) {
          let cleanedValue = value
            .replace(/\.xlsx/i, '')
            .replace(/xlsx/i, '')
            .replace(/File uploaded successfully/i, '')
            .replace(/[^a-zA-Z0-9]/g, '')
            .trim();

          messageData = createMessageData(
            `${cleanedValue}chatbot_gui${uid}.xlsx`, null, null, false,
            {
              browser_storage: sendStorageData(),
              current_ip: localStorage.getItem(uid + '|ip_address'),
            }
          );
        } else {
          messageData = createMessageData(
            value.trim(), null, null, false,
            {
              browser_storage: sendStorageData(),
              current_ip: localStorage.getItem(uid + '|ip_address'),
            }
          );
        }

        if (!buttonselected) {
          const newMessage = {
            sender: 'user',
            message_type: 'text_message',
            text: messageData.label_text,
            sent_by: 0,
          };
          setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      }
      else {
        messageData = createMessageData(
          JSON.stringify(humanagent) ? JSON.stringify(humanagent) : value.trim(),
          null,
          humanagent,
          JSON.stringify(humanagent) ? true : false
        );

        if (value.trim() !== '' || humanagent) {
          const newMessage = {
            sender: 'user',
            message_type: JSON.stringify(humanagent) ? 'form' : 'text_message',
            text: JSON.stringify(humanagent) ? humanagent : value.trim(),
            sent_by: 0,
          };
          setDefaultMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      }

      if (messageData.text) {
        if (HumanAgentSocket) {
          if (humanAgentSocketRef?.current) {
            humanAgentSocketRef.current.send(messageData);
          } else {
            console.error("HumanAgentSocket is not initialized or not connected.");
          }
        } else {
          socket.send(messageData);
        }

        // if (inputRef.current) {
        //   inputRef.current.value = '';
        // }

        setValidationRules(null);
        resolve();
      }
    });
  };

  const formatTimeQueueTime = (timeArray: any) => {
    // Extract minutes and seconds from the array
    const [minutes, seconds] = timeArray;

    // Format with leading zeros
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // Return formatted time string
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const submitFeedback = async (feedback: any) => {
    const feedbackData = {
      rating: feedback.rating,
      comment: feedback.comment,
    };

    const requestData = {
      smart_bot_key: botId,
      feedback_data: feedbackData,
      uid: uid,
      visitor_id: ""
    };
    try {
      const response = await fetch(`${socket_url}/feedback-rating`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit feedback');
      }
      else {
        localStorage.setItem(uid + "|feedback_submitted", "true");
        setShowFeedbackForm(false);
        setShowHamburgerMenu(false);
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const sendStorageData = (): string => {
    try {
      if (localStorage.length > 0) {
        const localData: { [key: string]: string } = {}; // Object to hold the filtered localStorage data
        for (let i = 0; i < localStorage.length; i++) {
          const localKey = localStorage.key(i);
          if (localKey && localKey.startsWith(uid)) {
            localData[localKey] = localStorage.getItem(localKey) || ""; // Add the item to the object
          }
        }
        return JSON.stringify(localData); // Convert the object to a JSON string
      } else {
        return ""; // If there's no data in localStorage, return an empty string
      }
    } catch (e) {
      console.error("Error accessing localStorage", e);
      return ""; // In case of error, return an empty string
    }
  };

  const setConfiguration = (response: any) => {
    setwidgetMessageBgColor(response.widget_message_bgcolor)
    setwidgetMessageColor(response.widget_message_color)
    setwidgetIconbgcolor(response.chat_widget_bgcolor)
    setIsReloadChat(response.reload_chat.is_enable)
    setIsDownloadChat(response.download_chat.is_enable)
    setIshamburger(response.action_center.is_enable)
    setheaderTextColor(response.text_color)
    setwidgetMessage(response.chat_widget_message_config)
    setwidgetMessageFlag(response.chat_widget_message)
    setHumanAgentIconFlag(response.human_agent_icon ?? 'false')
    setMessageBorderColor(response.webchat_widget_border_color)
    setwidgetIcon(response.webchat_widget_icon ?? response.webchat_widget_default_icon)
    setISavatarText(response.icon_text.avatar_as_text)
    if (response.icon_text.avatar_as_text === "true") {
      setavatarImage(response.icon_text.text)
    }
    else {
      setavatarImage(response.icon_text.logo || response.default_icon_text)

    }
    setMenuOptions(response.menu_options)
    setsetpowerdByLogo(response.powered_by.logo)
    setsetpowerdByText(response.powered_by.text)
    setpowerbyTextColor(response.powered_by.color)

    const colors = response.bg_gradient_color.match(/rgb\(\s?[0-9]{1,4},\s?[0-9]{1,4},\s??[0-9]{1,4}\)|rgba\([0-9]{1,4},\s??[0-9]{1,4},\s??[0-9]{1,4},\s??[0-1]*.?[0-9]+\)|#[0-9a-fA-F]{3,6}/g);
    // Match percentages
    let header_color_1, header_color_2, left_color_value, right_color_value, gradient_range_value
    const percentages = response.bg_gradient_color.match(/[0-9]*%/g);
    if (colors && colors.length >= 2) {
      header_color_1 = colors[0];
      header_color_2 = colors[1];
    }
    if (percentages && percentages.length >= 2) {
      left_color_value = percentages[0] || '0%';
      right_color_value = percentages[1] || '100%';
      gradient_range_value = left_color_value.replace('%', '');
    }
    setheaderImage(response.header_logo.logo ? response.header_logo.logo : response.default_header_logo)
    setheaderLogoIsEnable(response.header_logo.is_enable)
    setheaderName(response.header_name)
    setleftMessageBgColor(response.left_bubble.bubble_color)
    setleftMessageTextColor(response.left_bubble.chat_text_color)
    setrightMessageBgColor(response.right_bubble.bubble_color)
    setrightMessageTextColor(response.right_bubble.chat_text_color)
    setheaderBgColor(response.bg_gradient_color)
    localStorage.setItem("splashScreenIsenable", response.splash_screen.is_enable);
    if (response.splash_screen.is_enable === 'true') {
      if (localStorage.getItem('splashShown') === 'true') {
        setShowSplash(false)
      } else {
        setShowSplash(true)
        setTimeout(() => {
          setShowSplash(false);
          setIsNewBotMessage(false)
          localStorage.setItem("splashShown", "true");
        }, 3000);
      }
      if (response.splash_screen.logo === "") {
        setsplashImage(response.default_splash_screen);
      } else {
        setsplashImage(response.splash_screen.logo);
      }
    } else {
      setShowSplash(false)
    }

  }

  const getchatHistory = async (botId: string, uid: string | null) => {
    try {
      const storedData = localStorage.getItem(uid + '|unique_chat_session_ids');

      let uniqueChatSessionId = null;

      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData); // Parse the JSON string
          if (parsedData.values && Array.isArray(parsedData.values) && parsedData.values.length > 0) {
            uniqueChatSessionId = parsedData.values[0].unique_chat_session_id; // Access the ID of the first object
          }
        } catch (error) {
          console.error("Failed to parse localStorage data:", error);
        }
      }
      const response = await axios.post(`${socket_url}/chat-history`, {
        data: {
          cache_prefix: "chatbot_gui" + localStorage.getItem('uid'),
        },
        bot_key: localStorage.getItem('botId'),
      });
      const chatHistory = response.data.chatbot_gui?.hits?.hits;

      // Check if chatHistory is an array and not empty
      if (Array.isArray(chatHistory) && chatHistory.length > 0) {
        chatHistory.forEach((message: any) => {
          var historyMessage = message._source
          if (message._source.sent_by === 1 || message._source.sent_by === 3) {
            const messageText = historyMessage.payload.text;

            // Handle text messages
            if (
              messageText &&
              messageText.trim() !== '' &&
              !(messageText.trim().startsWith('{') && messageText.trim().endsWith('}'))
            ) {
              const textMessage = {
                sender: 'bot',
                message_type: 'text_message',
                text: messageText,
                sent_by: message._source.sent_by,
              };

              setDefaultMessages((prevMessages) => {
                const updatedMessages = [...prevMessages, textMessage];
                return updatedMessages;
              });
            }

            // Handle button messages
            const buttons: { title: string; value: string }[] | undefined =
              message._source.payload.attachments?.[0]?.content?.buttons;

            if (buttons && buttons.length > 0) {
              const buttonMessage = {
                sender: 'bot',
                message_type: 'button_history',
                buttons: buttons.map((button) => ({
                  title: button.title,
                  value: button.value,
                })),
                sent_by: message._source.sent_by,
                message_no: message._source.message_no,
              };

              const textMessage = {
                sender: 'bot',
                message_type: 'text_message',
                text: message._source.payload.attachments?.[0]?.content?.text || "", // Ensure textMessage has a default empty string
                sent_by: message._source.sent_by,
              };
              setDefaultMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];

                // Only add textMessage if it has non-empty text
                if (textMessage.text.trim() !== '') {
                  updatedMessages.push(textMessage);
                }

                // Add buttonMessage if buttons exist
                if (buttons && buttons.length > 0) {
                  updatedMessages.push(buttonMessage);
                }

                return updatedMessages;
              });
            }
          }

          else if (message._source.sent_by === 0 || message._source.sent_by === 2) {
            var newMessage: any;

            try {
              // First check if the text looks like JSON
              if (historyMessage.text.trim().startsWith('{') && historyMessage.text.trim().endsWith('}')) {
                const parsedText = JSON.parse(historyMessage.text);

                // Check if any key in the parsed object starts with 'submit_'
                const hasSubmitKey = Object.keys(parsedText).some(key => key.startsWith('submit_'));

                if (hasSubmitKey) {
                  const transformedData = Object.entries(parsedText).reduce((acc, [key, value]) => {
                    // Only add to accumulator if value is not empty string or null or undefined
                    if (value && value.toString().trim() !== '') {
                      acc[key] = {
                        title: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
                        value: value as string
                      };
                    }
                    return acc;
                  }, {} as Record<string, { title: string; value: string }>);

                  // Only create form message if there are non-empty values
                  if (Object.keys(transformedData).length > 0) {
                    newMessage = {
                      sender: "user",
                      message_type: 'createAdForm',
                      text: transformedData,
                      sent_by: 0,
                    };
                  } else {
                    // Fallback for when all values are empty
                    newMessage = {
                      sender: 'user',
                      message_type: message._source.payload.type,
                      text: historyMessage.text,
                      sent_by: 0
                    };
                  }
                } else {
                  // No submit_ key found, treat as normal message
                  newMessage = {
                    sender: 'user',
                    message_type: message._source.payload.type,
                    text: historyMessage.text,
                    sent_by: 0
                  };
                }
              } else {
                // Not JSON, handle as normal text
                newMessage = {
                  sender: 'user',
                  message_type: message._source.payload.type,
                  text: historyMessage.text,
                  sent_by: 0
                };
              }
            } catch (e) {
              // Handle any parsing errors
              newMessage = {
                sender: 'user',
                message_type: message._source.payload.type,
                text: historyMessage.text,
                sent_by: 0
              };
            }

            setDefaultMessages(prevMessages => {
              const updatedMessages = [...prevMessages, newMessage];
              return updatedMessages;
            });
          }
          else if (message._source.sent_by === 6) {
            const newMessage = {
              sender: 'user',
              message_type: "system_message",
              text: historyMessage.text,
              sent_by: 0
            };
            setDefaultMessages(prevMessages => {
              // Prevent unnecessary re-renders
              const updatedMessages = [...prevMessages, newMessage];
              return updatedMessages;
            });
          }
          setIsNewBotMessage(false);
        });
      }
      else {
        // console.error("Invalid chat history format or chatHistory is empty");
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  const fetchChatData = async () => {
    setDefaultMessages([])
    try {
      const response = await axios.post(`${socket_url}/get-webchat-data?bot_id=${botId}`, {
        language: 'en'
      });
      setConfiguration(response.data);
      const data = response.data;

      const apiVersion = response.data.api_version;
      if (apiVersion) {
        socket_url = socket_url?.replace(/\/v\d+$/, `/${apiVersion}`);
        getchatHistory(botId, uid);
        initializeSocket();
      }
      else {
        getchatHistory(botId, uid);
        initializeSocket();
      }
      const messagesWithSender = (data.default_message_payload || []).map((message: any) => {
        const buttons =
          message.message_type === "button" && message.attachments
            ? message.attachments.flatMap((attachment: any) => attachment.content.buttons || [])
            : [];

        return {
          sender: 'bot',
          message_type: message.message_type,
          text: message.text || '', // Handle case where text may not exist
          buttons: buttons, // Include buttons if available
          sent_by: 1,
          default: true
        };
      });

      setIsNewBotMessage(true);
      setDefaultMessages((prevMessages) => [...prevMessages, ...messagesWithSender]);
      setIsloading(false);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevents parent handlers from interfering
    e.preventDefault();
    isTypingRef.current = true;
    // setInputText(inputValue);
    if (inputRef.current) {
      inputRef.current.value = e.target.value
    }
    let input = e.target.value.trim();
    // Emit typing event if socket is available
    if (humanAgentSocketRef.current) {
      humanAgentSocketRef.current.emit('user_typing', {
        'typing_data': input,
        'bot_id': botId,
        "user_uid": uid
      });
    }
  };

  const getCookie = (cname: string): string => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let cookie of cookieArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  };

  const setCookie = (cname: string, cvalue: any, exdays: number): void => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=None;Secure`;
  };

  const handleFormSubmit = (formData: any) => {
    if (formData && typeof formData === 'string') {
      try {
        const parsedFormData = JSON.parse(formData);
        handleSendMessage("", "", "", parsedFormData);
      } catch (error) {
        console.error("Failed to parse form data:", error);
      }
    } else {
      let createForm: Record<string, any> = {};

      if (formData) {
        // Dynamically reduce formData to create the payload with value and title
        createForm = Object.keys(formData).reduce((acc: any, key) => {
          const field = formData[key];

          if (key === "ou_name" && typeof field === "object") {
            // Include display_name specifically for ou_name
            acc[key] = {
              value: field?.value || "",
              title: field?.title || "",
              display_name: field?.display_name || "",
            };
          } else if (field instanceof FileList && field.length > 0) {
            // Handle file specifically
            const file = field[0]; // Get the first file
            // Assuming a separate key for display_name
            acc[key] = file.name;
          } else {
            // Default case for other keys
            acc[key] = typeof field === "object"
              ? { value: field?.value || "", title: field?.title || "", buttonId: field?.buttonId }
              : field || "";
          }
          return acc;
        }, {});

        const hasFile = createForm.display_name === "File Uploaded"
        if (hasFile) {
          let titleMessage = "";
          try {
            // Parse the title if it is a stringified JSON
            const parsedTitle = JSON.parse(createForm.title || "{}");
            titleMessage = parsedTitle.message || "";
          } catch (error) {
            console.error("Failed to parse title:", error);
          }
          const success = `${createForm.value} ${titleMessage}`;        // Handle file upload logic
          handleSendMessage(
            success, "", "", "", "", true
          );
        } else {
          handleSendMessage("", "", "", createForm);
        }
      }
    }
    setCreateForm(null);
  };

  const startTimer = () => {
    const initialQueueTime = JSON.parse(localStorage.getItem("queue_time") || "{}");
    const [initialMinutes, initialSeconds] = initialQueueTime[uid] || [0, 0];
    elapsedTimeRef.current = initialMinutes * 60 + initialSeconds;
    if (timerElementRef.current) {
      timerElementRef.current.textContent = formatTime(elapsedTimeRef.current);
    }
    const timer = setInterval(() => {
      elapsedTimeRef.current += 1;
      const updatedMinutes = Math.floor(elapsedTimeRef.current / 60);
      const updatedSeconds = elapsedTimeRef.current % 60;
      localStorage.setItem(
        "queue_time",
        JSON.stringify([updatedMinutes, updatedSeconds])
      );
      if (timerElementRef.current) {
        timerElementRef.current.textContent = formatTime(elapsedTimeRef.current);
      }
      if (elapsedTimeRef.current % 60 === 0 && humanAgentSocketRef.current) {
        humanAgentSocketRef.current.emit("every_minute", {
          minutes: updatedMinutes.toString(),
          bot_id: botId,
          user_uid: uid,
        });
      }
      if (shouldAutoDisconnect) {
        clearInterval(timer);
        disconnect_to_human_agent({ flag: "auto_disconnect" });
      }
    }, 1000);
    timerIdRef.current = timer;
    globalThis.runningTimer = timerIdRef.current; // Store timer globally
  };

  const stopTimer = () => {
    setTimer(false);
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
    if (timerElementRef.current) {
      timerElementRef.current.textContent = "00:00";
    }
    elapsedTimeRef.current = 0;
    const queueTime = JSON.parse(localStorage.getItem("queue_time") || "{}");
    delete queueTime[uid];
    localStorage.setItem("queue_time", JSON.stringify(queueTime));
  };

  const formatTime = (seconds: number) => {
    const minute = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minute.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const rebootOrReloadPage = async (operationType: 'reboot' | 'reload') => {
    setShowHamburgerMenu(false)
    setShowProgress(false)
    progressRef.current = 0;
    setIsTyping(false)
    sessionStorage.clear();
    setSelectedButtonIndex({});
    if (operationType === 'reboot') {
      if (localStorage.getItem("splashScreenIsenable") === "true") {
        setShowSplash(true)
      }
      localStorage.clear();
      const visitorChatData = {
        uid: uid, // Replace with actual UID if available in your React state or context
      };
      const newUid = btoa(Math.random().toString()).replace(/=|\+|\//g, '');
      try {
        // Await the API response
        const response = await fetch(`${socket_url}/clear-chat-history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(visitorChatData),
        });

        // Check if the response was successful
        if (response.ok) {
          setShowHamburgerMenu(false)
          doReset(newUid);
        } else {
          console.error('Failed to clear chat history:', response.statusText);
        }
      } catch (error) {
        console.error('Error clearing chat history:', error);
      }
    }
    else {
      setIsloading(true);
      const visitorChatData = {
        uid: uid, // Replace with actual UID if available in your React state or context
      };

      try {
        // Await the API response
        const response = await fetch(`${socket_url}/clear-chat-history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(visitorChatData),
        });

        // Check if the response was successful
        if (response.ok) {
          doReset();
        } else {
          console.error('Failed to clear chat history:', response.statusText);
        }
      } catch (error) {
        console.error('Error clearing chat history:', error);
      }
    }
  };

  const onDownloadClick = async () => {
    const locationResponse = await fetch("https://api.db-ip.com/v2/free/self");
    const locationData = await locationResponse.json();
    const formattedLocationData = {
      ipAddress: locationData.ipAddress,
      continentCode: locationData.continentCode,
      continentName: locationData.continentName,
      countryCode: locationData.countryCode,
      countryName: locationData.countryName,
      stateProv: locationData.stateProv,
      city: locationData.city,
    };
    const userLocationData = JSON.stringify(formattedLocationData, null, 2);
    const currentHour = new Date().getHours();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let greetingMessage = "Good Evening";
    if (currentHour >= 5 && currentHour < 12) {
      greetingMessage = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      greetingMessage = "Good Afternoon";
    }
    const translatedGreet = undefined;  // Replace with actual translation logic if needed
    const USIDRaw = localStorage.getItem(uid + '|unique_chat_session_ids');

    if (USIDRaw) {
      try {
        const USIDArray = JSON.parse(USIDRaw); // Parse JSON string to an array
        const lastSession = USIDArray.values[USIDArray.values.length - 1]; // Get the last object
        var uniqueChatSessionId = lastSession?.unique_chat_session_id; // Extract the ID
        console.log(uniqueChatSessionId); // Use it as needed
      } catch (error) {
        console.error("Error parsing unique_chat_session_ids:", error);
      }
    }

    const visitorChatData = {
      uid,
      bot_id: botId,
      user_location_data: userLocationData,
      username: localStorage.getItem("user_full_name") || "",
      greet: translatedGreet || greetingMessage,  // Use translated greeting or fallback to default
      timezone: timeZone,
      webchat_gui_url: window.location.origin,
      user_info: { ...localStorage },
      download_chat_history: true,
      report_chat_history: false,
      unique_chat_session_id: uniqueChatSessionId || '',
    };

    try {
      const response = await fetch(`${socket_url}/download-chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(visitorChatData),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.report_to !== "admin") {
          const link = document.createElement("a");
          const dataURI = "data:text/html," + encodeURIComponent(data.chat_history_html);
          link.download = data.file_name;
          link.href = dataURI;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          const message =
            data.status === 200
              ? "I have sent chat history to your IT Support Team."
              : "Failed to send chat history to your IT Support Team. Kindly try again.";
          alert(message);
        }
      } else {
        console.error("Failed to fetch chat history:", response.statusText);
      }
    } catch (error) {
      console.error("Error downloading chat history:", error);
    }
  };

  const toggleChatbot = (isreboot?: any) => {
    setIsNewBotMessage(false)
    if (isreboot != 'reboot') {
      if (isOpen) {
        setIsOpen(false);
        setTimeout(() => {
          setShouldRender(false);
          setIsNewBotMessage(false)
          localStorage.setItem("IsBotOpen", "false"); // Move here for closing
        }, 500);
      } else {
        if (showSplash) {
          setShouldRender(true);
          setIsOpen(true);
          localStorage.setItem("IsBotOpen", "true"); // Move here for opening with splash
          setTimeout(() => {
            setShowSplash(false);
            setIsNewBotMessage(false)
            localStorage.setItem("splashShown", "true");
          }, 3000);
        } else {
          setShouldRender(true);
          setIsOpen(true);
          localStorage.setItem("IsBotOpen", "true"); // Move here for direct opening
        }
      }
    }
    if (isreboot === 'reboot') {
      if (localStorage.getItem("splashScreenIsenable") === "true") {
        setShowSplash(true);
      }
      setShouldRender(true);
      setIsOpen(true);
      localStorage.setItem("IsBotOpen", "true"); // Move here for reboot
      setTimeout(() => {
        setShowSplash(false);
        setIsNewBotMessage(false)
        localStorage.setItem("splashShown", "true");
      }, 3000);
    }
  };

  const handleReloadClick = () => {
    rebootOrReloadPage('reload');
    setIsSpinning(true);
    setTimeout(() => setIsSpinning(false), 500); // Stops the spinning after 2 seconds
  };
  const handleMenuClick = (funcName: string) => {
    const [functionName] = funcName.split(',');
    if (functionName === 'reboot_or_reload_page') {
      rebootOrReloadPage('reboot');
    } else if (funcName === 'previous_chats') {
      setShowChatHistory(true)
    } else if (funcName === 'assistant_language') {
      setShowTranslateModal(true);
    } else if (funcName === 'feedback') {
      let feedbackSubmitted = localStorage.getItem(uid + "|feedback_submitted")
      if (feedbackSubmitted) {
        setSubmittedModal(true)
      }
      else {
        setShowFeedbackForm(true);
      }
    };
  }

  const handleFeedbackData = (data: any) => {
    if (data === "close") {
      setShowFeedbackForm(false)
    }
    else {
      submitFeedback(data);  // Call the API from here
    }
  };

  const ChatScreen = () => {
    return (
      <div
        className={`chat-screen ${showFeedbackForm ? "blurred-background" : ""}`}
        style={{
          backgroundImage: `url(${chatBgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <ChatHeader
          {...{
            headerLogoIsEnable,
            headerImage,
            headerTextColor,
            headerName,
            handleReloadClick,
            isSpinning,
            onDownloadClick,
            HumanAgentIconFlag,
            handleAgentButtonClick,
            HumanAgentSocket,
            PUBLIC_PATH,
            uid,
            IsReloadChat,
            IsDownloadChat,
            headerBgColor: headerBgColor, // Pass the header background color (defined in parent)
            closeHamburgerMenu: () => setShowHamburgerMenu(false), // Pass a function instead of calling it directly
          }}
        />


        {showProgress && (
          <ProgressBar showProgress={showProgress} progressRef={progressRef} />
        )}

        <div className={`chat-messages ${powerdByText ? 'chat-power' : ''}`}
          style={{
            overflowY: 'auto',
            scrollBehavior: 'auto'
          }}>
          {Isloading ? (<ChatSkeleton />) : (
            <div className="chat_message_inner">
              {defaultMessages.length > 0 && (
                <div className="loading">Today</div>
              )}
              {defaultMessages.map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  ISavatarText={ISavatarText}
                  headerBgColor={headerBgColor}
                  headerTextColor={headerTextColor}
                  avatarImage={avatarImage}
                  leftMessageBgColor={leftMessageBgColor}
                  leftMessageTextColor={leftMessageTextColor}
                  rightMessageBgColor={rightMessageBgColor}
                  rightMessageTextColor={rightMessageTextColor}
                  handleButtonClick={handleButtonClick}
                  selectedButtonIndex={selectedButtonIndex}
                  index={index}
                  defaultMessages={defaultMessages}
                  isNewBotMessage={isNewBotMessage}
                  viewlessrRef={viewlessrRef}
                  scrollToBottom={scrollToBottom}
                  timer={timer}
                  timerElementRef={timerElementRef} />
              ))}

              {isTyping && (
                <div className="bubble_main">
                  <div className="botmsg_right_inner">
                    {ISavatarText === "true" ? (
                      <span
                        className="assistant_bot_icon"
                        style={{
                          background: headerBgColor,
                          color: headerTextColor,
                        }}
                      >
                        {avatarImage}
                      </span>
                    ) : (
                      <img src={avatarImage} alt="Chat" />
                    )}
                  </div>

                  <div className="chat-bubble received" style={{
                    backgroundColor: leftMessageBgColor
                  }}>
                    <span className="typing">
                      <span className="dot"></span>
                      <span className="dot"></span>
                      <span className="dot"></span>
                    </span>
                    <svg className="chat-tail" width="14" height="21" viewBox="0 0 14 21" style={{ filter: "drop-shadow(rgba(1, 1, 0, 0.4) -8px 3px 6px)" }}>
                      <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                      </path>
                    </svg>
                    <svg className="chat-tail1" width="14" height="21" viewBox="0 0 14 21" >
                      <path d="M0 5.39C6.05 6.83 12.79 13.18 14 20.75V0.129997C9.05 -0.610003 4.12 1.91 0 5.39Z" fill={leftMessageBgColor}>
                      </path>
                    </svg>

                    {/* <div className="tail" style={{
                      backgroundColor: leftMessageBgColor
                    }}></div>
                    <div className="tail1" style={{
                      backgroundColor: leftMessageBgColor
                    }}></div> */}
                  </div>
                </div>
              )}

              {createForm && (
                <div ref={formRef}>
                  <CreateAdUser formJson={createForm} onSubmit={handleFormSubmit} />
                </div>
              )}

              {fileUploadUserFormJson && (
                <div
                  className="upload-form-container"
                  ref={(el) => {
                    if (el) {
                      el.scrollIntoView({ behavior: "smooth", block: "start" });
                    }
                  }}
                >
                  <div className="upload-form-content">
                    <div className="human_attachment_wrp">
                      <img src={`${PUBLIC_PATH}/human_attachment_img.svg`} alt="#" />
                    </div>
                    <h3 className="upload-title">{fileUploadUserFormJson.TITLE_VALUE}</h3>

                    {fileUploadUserFormJson.FORM_ELEMENTS.sort((a: any, b: any) => {
                      // Ensure INPUTs come before BUTTONs
                      if (a.INPUT && !b.INPUT) return -1;
                      if (!a.INPUT && b.INPUT) return 1;
                      return 0;
                    }).map((element: any, idx: number) => (
                      <div key={idx} className="form-element">
                        {element.INPUT &&
                          element.INPUT.map((input: any) => (
                            <div key={input.id} className="file-input-container">
                              <div className="custom-file-input">
                                <input
                                  id={input.id}
                                  type="file"
                                  accept={input.accept}
                                  required={input.required}
                                  onInput={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, input)}
                                />
                              </div>
                              {error && (
                                <small className="error-message">
                                  {input.validation_error}
                                </small>
                              )}

                              {input.snap && (
                                <div className="warning_note_wrp">
                                  <div className="warning_note_img">
                                    <img src={`${PUBLIC_PATH}/note_img.svg`} alt="#" />

                                  </div>
                                  <div className="close_note">
                                    <span className="close_note_text"
                                      dangerouslySetInnerHTML={{ __html: input.snap }}>
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}

                        {element.BUTTON &&
                          element.BUTTON.map((button: any) => (
                            <div key={button.id} className="form-button-container">
                              <button
                                type="button"
                                className="primary_human"
                                onClick={() => handleSendMessage("", "", "", "", fileRef.current)}
                              >
                                {button.title}
                              </button>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Submitted feedback modal */}
              {SubmittedModal && (
                <Modal
                  message="You have already submitted feedback."
                  onClose={handleCloseSubmitterModal}
                />
              )}
            </div>)
          }
          <div ref={chatContainerRef}></div>
        </div>

        {/* Input Area */}
        <div className="powerby_inner">
          <div className="sent_input_main">
            <div className="chat-input-container">
              {Ishamburger === "true" && (
                <button
                  className="hamburger-btn"
                  onClick={() => {
                    setShowHamburgerMenu(!showHamburgerMenu);
                    setIsNewBotMessage(false);
                  }}
                >
                  ☰
                </button>
              )}
              <form className="send_form" onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
                handleSendMessage(inputRef.current?.value.trim());
              }}>
                <input
                  type="text"
                  className="form-control input-sm chat_input"
                  placeholder="Write a message"
                  onChange={handleInputChange}
                  ref={inputRef}
                  disabled={!!(showHamburgerMenu || showFeedbackForm || validationRules || createForm)}
                  autoFocus
                />

                <button type="submit" className="send_button"
                  style={{
                    background: headerBgColor,
                  }}>
                  <img src={`${PUBLIC_PATH}/send_msg_img.svg`} alt="" />
                  {/* <Send size={20} /> */}
                </button>
              </form>
            </div>
          </div>
          <div className="powered_by_wrp">
            {powerdByText ? (
              <i style={{ color: powerbyTextColor }}> {powerdByText}</i>
            ) : ''}
            {powerdByLogo ? (
              <img src={powerdByLogo} alt="" />
            ) : ''}
          </div>
        </div>
        {
          showHamburgerMenu && (
            <div className={`action-center ${powerdByText ? 'action-power' : ''} ${animationPlayed ? 'no-animation' : 'slide-animation'}`} >
              <div className="action-center-header">
                <h3>Action Center</h3>
                <button onClick={() => setShowHamburgerMenu(false)} className="close-btn">
                  <X size={20} />
                </button>
              </div>
              <ul className="action-center-options">
                {menuOptions.map((option, index) => {
                  const isFeedbackDisabled = option.func_name === "feedback" && !localStorage.getItem(`${localStorage.getItem('uid')}|unique_chat_session_ids`);
                  return (
                    <li key={index}
                      onClick={() => !isFeedbackDisabled && handleMenuClick(option.func_name)}
                      style={{ opacity: isFeedbackDisabled ? 0.5 : 1, pointerEvents: isFeedbackDisabled ? "none" : "auto" }}>
                      <a href={option.href}>
                        <img src={option.icon} alt="img" height={'15px'} />
                        <span>{option.name}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        }

        {
          showFeedbackForm && (
            <div className="feedback-form-overlay">
              <FeedbackForm onSubmitFeedback={handleFeedbackData} />
            </div>
          )
        }

        {
          showChatHistory && (
            <div className="chathistory-form-overlay">
              <ChatHistory ref={chatHistoryRef} botId={botId} onClose={handleCloseChatHistory} />
            </div>
          )
        }

        {
          showTranslateModal && (
            <div className="language-form-overlay">
              <TranslateLanguageComponent onBack={handleCloseModal} botId={botId} />
            </div>
          )
        }

        {
          successMessage && (
            <div style={{ color: 'green', marginTop: '10px' }}>
              {successMessage}
            </div>
          )
        }

      </div >
    )
  };

  return (
    <div className="chatbot-main">
      {/* Chatbot Window */}
      {shouldRender && (
        <div className={`chatbot-window ${localStorage.getItem("IsBotOpen") === "true" ? "" : isOpen ? "slide-up" : "slide-down"}`}>
          {showSplash ? <SplashScreen splashImage={splashImage} /> : <ChatScreen />}

        </div>
      )}

      {/* Widget Toggle */}
      <div className="avtar_name_wrp">
        {!isOpen && widgetMessageFlag === 'true' && (
          <span
            className="state_messages"
            style={{
              background: widgetMessageBgColor,
              color: widgetMessageColor,
              borderColor: MessageBorderColor,
            }}
          >
            {parse(widgetMessage)}
          </span>
        )}
        {widgetIcon &&
          (<button
            onClick={toggleChatbot}
            className={`toggle-button ${isOpen ? "rotated" : ""}`}
            style={{ background: widgetIconbgcolor }}
          >
            {isOpen ? (
              <div>
                <X size={20} className="close_bot" />
              </div>) : (
              <img src={widgetIcon} className="w-6 h-6" />
            )}
          </button>
          )
        }
      </div>
    </div>
  );
}

export default ChatbotInterface;

const Modal: React.FC<{ message: string; onClose: () => void }> = ({ message, onClose }) => {
  return (
    <div className="feedback-form-overlay">
      <div className="modal-overlay">
        <div className="feedback-content">
          <div className="feed_img">
            <img src={`${PUBLIC_PATH}/feedback_send_img.svg`} alt="" />
          </div>
          <button className="modal-close" onClick={onClose}>
            <X size={20} />
          </button>
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
}