import React, { useState, ChangeEvent, FormEvent } from "react";
import "./CreateAdUser.css";
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'; // Import default styles
import axios from 'axios';
let socket_url = process.env.REACT_APP_SOCKET_SERVER_URL

interface FormInput {
  id: string;
  title: string;
  type: string;
  required: string;
  validation_pattern: string;
  validation_error: string;
  value: any,
  snap: any
}

interface FormButton {
  id: string;
  title: string;
}

interface FormElement {
  INPUT?: FormInput[];
  BUTTON?: FormButton[];
  SELECT?: {
    id: string;
    title: string;
    value?: any;
    OPTIONS: {
      value: string;
      display_name: string;
    }[];
  }[];
  CHECKBOX?: {
    id: string;
    title: string;
    value?: any;
    OPTIONS: {
      value: string;
    }[];
  }[];
}

export interface FormJson {
  TITLE_VALUE: string;
  FORM_ID?: string,
  FORM_ELEMENTS: FormElement[];
}

interface CreateAdUserProps {
  formJson: FormJson;
  onSubmit: (formData: any) => void;
}

const CreateAdUser: React.FC<CreateAdUserProps> = ({ formJson, onSubmit }) => {
  const [selectedTerms, setSelectedTerms] = useState<string[]>([]);
  const [formData, setFormData] = useState<Record<any, any | { value: any; display_name: any; title?: any; buttonId: any }>>(() => {
    const initialData: Record<any, any | { value: any; display_name: any; title?: any }> = {};

    formJson.FORM_ELEMENTS.forEach((element) => {
      // Handle INPUT fields
      element.INPUT?.forEach((input) => {
        initialData[input.id] = {
          value: input.value || "", // Default to an empty string if no value
          title: input.title, // Add title for INPUT fields
        };
      });

      element.SELECT?.forEach((selectElement) => {
        const defaultValue = selectElement.value || ""; // Default value provided for the SELECT field
        if (defaultValue) {
          const selectedOption = selectElement.OPTIONS.find(
            (option) => option.value === defaultValue
          );
          if (selectedOption) {
            initialData[selectElement.id] = {
              value: selectedOption.value,
              display_name: selectedOption.display_name,
            };
          } else {
            initialData[selectElement.id] = {
              value: "",
              display_name: "",
            };
          }
        } else {
          initialData[selectElement.id] = {
            value: "",
            display_name: "",
            title: selectElement.title
          }; // No default value
        }
      });
    });

    return initialData;
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedTerms((prev) => [...prev, id]);
    } else {
      setSelectedTerms((prev) => prev.filter((term) => term !== id));
    }
  };


  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    id: string,
    display_name?: { value: string; display_name: string },
    file?: File
  ) => {
    const formElement = formJson?.FORM_ELEMENTS?.find(
      (el: FormElement) =>
        (el.CHECKBOX && el.CHECKBOX.some((item) => item.id === id)) ||
        (el.SELECT && el.SELECT.some((item) => item.id === id)) ||
        (el.INPUT && el.INPUT.some((item) => item.id === id))
    );
    const existingTitle =
    formElement?.CHECKBOX?.find((item) => item.id === id)?.title ||
    formElement?.SELECT?.find((item) => item.id === id)?.title ||
    formElement?.INPUT?.find((item) => item.id === id)?.title;
    const buttonId = formJson?.FORM_ELEMENTS?.find((element) => element.BUTTON)?.BUTTON?.[0]?.id;

    // Handle display_name scenario first
    if (display_name) {
      const newValue = {
        value: display_name.value,
        display_name: display_name.display_name,
        title: existingTitle,
        buttonId: buttonId
      };

      setFormData((prevData) => ({
        ...prevData,
        [id]: newValue,
      }));
      return;
    }

    // Type guard to check if it's an HTMLInputElement
    if (e.target instanceof HTMLInputElement &&
      e.target.type === 'checkbox' &&
      (file === null || file === undefined)) {
      // If there's existing value, ensure it's an array, otherwise start with empty array
      const existingArray: string[] = Array.isArray(formData[id]?.value)
        ? formData[id].value
        : formData[id]?.value
          ? [formData[id].value]
          : [];

      // Get new value from checkbox
      const checkboxValue: string = e.target.value;

      // Add or remove value based on checkbox state
      let newArray: string[];
      if (e.target.checked) {
        // Add value if it doesn't exist already
        newArray = existingArray.includes(checkboxValue)
          ? existingArray
          : [...existingArray, checkboxValue];
      } else {
        // Remove value if unchecked
        newArray = existingArray.filter((value: string) => value !== checkboxValue);
      }

      // Store the array directly in formData
      const newValue = {
        value: newArray,  // No more join(',')
        title: existingTitle,
        buttonId: buttonId
      };

      setFormData(prevData => ({
        ...prevData,
        [id]: newValue
      }));
      return;
    }
    // Handle text, dropdown, and other single-value inputs
    const newValue = {
      value: e.target.value,
      title: existingTitle,
      buttonId: buttonId
    };

    setFormData((prevData) => ({
      ...prevData,
      [id]: newValue,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const form = e.currentTarget[0].files;
    if (form && form.length > 0) {
      const formDataToSubmit = new FormData(); // Create a new FormData object
      formDataToSubmit.append("uid", localStorage.getItem("uid") || "");
      for (let i = 0; i < form.length; i++) {
        formDataToSubmit.append("file", form[i]); // Correct field name
      }

      try {
        const response = await axios.post(`${socket_url}/upload-file`, formDataToSubmit, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        let newValue = {
          value: form,  // Or any other file-specific data you'd like to store
          display_name: "File Uploaded", // You can store the filename or any relevant data here
          title: response.data
        };

        // Handle success response
        console.log('File uploaded successfully:', response.data);
        onSubmit(newValue); // Pass the data to your onSubmit function or process as needed
      } catch (error) {
        console.error('File upload failed:', error);
      }
    }
    // else {
    //   if (selectedTerms.length !== 0) {
    //     const formattedTerms = selectedTerms.map(term => {
    //       const parts = term.split(',');
    //       return parts.join('<comma>');
    //     }).join(', ');

    //     const checkboxElement = formJson?.FORM_ELEMENTS.find((element) => element.CHECKBOX)?.CHECKBOX?.[0];
    //     const buttonElement = formJson?.FORM_ELEMENTS.find((element) => element.BUTTON)?.BUTTON?.[0];
    //     const data = JSON.stringify({
    //       [checkboxElement?.title || '']: formattedTerms,
    //       [buttonElement?.id || '']: '',
    //     });
    //     onSubmit(data);
    //   }
    else {
      onSubmit(formData);
    }

  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="create_ad_user">
        <div className="human-agent-form">
          <div className="human-agent-head">
            <h3 className="form-title">{formJson.TITLE_VALUE}</h3>
          </div>

          {formJson.FORM_ELEMENTS.map((element, index) => (
            <div className="form-group" key={index}>
              {element.INPUT?.map((input) => (
                <div className="human_input_inner" key={input.id}>
                  <label htmlFor={input.id}>{input.title}</label>
                  <div className={input.snap ? "custom-file-input" : ""}>
                    <input autoComplete="off"
                      id={input.id}
                      type={input.type}
                      required={input.required === "True"}
                      pattern={input.validation_pattern}
                      value={formData[input.id] !== undefined ? formData[input.id].value : (input?.value || '')}
                      onChange={(e) =>
                        input.type === "file"
                          ? handleChange(e, input.id, undefined, e.target.files?.[0]) // Pass the file if `type` is `file`
                          : handleChange(e, input.id)
                      }
                    />
                  </div>
                  {input.snap && (
                    <div
                      className="snap-content"
                      dangerouslySetInnerHTML={{ __html: input.snap }}
                    ></div>
                  )}
                  {formData[input.id]?.value && // Check if `value` exists
                    !new RegExp(input.validation_pattern).test(formData[input.id]?.value) && (
                      <small className="error">{input.validation_error}</small>
                    )}
                </div>
              ))}
              {element.SELECT?.map((selectElement) => (
                <div className="human_input_inner" key={selectElement.id}>
                  <label htmlFor={selectElement.id} className="dropdown-label">
                    {selectElement.title}
                  </label>
                  <SelectSearch
                    id={selectElement.id}
                    options={selectElement.OPTIONS.map(option => ({
                      name: option.display_name,
                      value: option.value
                    }))}
                    value={formData[selectElement.id]?.value || ""}
                    placeholder="-- Please Select --"
                    onBlur={() => { }}
                    search={true}
                    onFocus={() => { }}
                    onChange={(selectedValue) => {
                      const selectedOption = selectElement.OPTIONS.find(
                        option => option.value === selectedValue
                      );
                      if (selectedOption) {
                        handleChange(
                          { target: { value: selectedOption.display_name, name: selectElement.id } } as React.ChangeEvent<HTMLInputElement>,
                          selectElement.id,
                          selectedOption
                        );
                      }
                    }}
                  />
                </div>
              ))}

              {element.CHECKBOX?.map((checkbox) => (
                <div className={`human_input_inner ${formJson.FORM_ID}`} key={checkbox.id}>
                  <label>{checkbox.title}</label>
                  <div className="human_grid_wrp">
                    {checkbox.OPTIONS.map((option) => (
                      <div className="form_check_group" key={option.value}>
                        <input
                          className="form_check_input"
                          type="checkbox"
                          id={`${checkbox.id}_${option.value}`}
                          name={checkbox.id}
                          value={option.value}
                          onChange={(e) =>
                            handleChange(e, checkbox.id)
                          }
                        />
                        <label className="check_group" htmlFor={`${checkbox.id}_${option.value}`}>{option.value}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}

          <div className="human_form_btn">
            {formJson.FORM_ELEMENTS.map((element) =>
              element.BUTTON?.map((button) => (
                <button className="primary_human" key={button.id} id={button.id} type="submit">
                  {button.title}
                </button>
              ))
            )}
          </div>
        </div>
      </form>
    </div>
  );
};


export default CreateAdUser;
