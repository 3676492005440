import React from 'react';
import './skeletonLoader.css'

const ChatSkeleton = () => {
  return (
    <div className="tail_main space-y-6 pt-3">
       {/* <div className="w-full h-16 bg-gray-200 rounded-t-lg animate-pulse" ></div> */}

      {/* Left side message skeleton */}
      <div className="flex items-start space-x-3 animate-pulse">
        <div className="w-8 h-8 rounded-full bg-gray-200"></div>
        <div
          className="tail_left h-8 w-48 bg-gray-200 rounded-lg"
        ></div>
        <div className="flex flex-col space-y-3">
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
        </div>
      </div>

      {/* <div className="flex flex-wrap gap-2 pl-16 animate-pulse">
        {[1, 2].map((item) => (
          <div
            key={item}
            className="h-5 w-20 bg-gray-200 rounded-medium"
          ></div>
        ))}
      </div> */}
      {/* Right side message skeleton */}
      <div className="flex items-start justify-end space-x-3 animate-pulse">
      {/* <div
          className="tail_right h-8 w-48 bg-gray-200 rounded-lg"
        ></div> */}
        <div className="flex flex-col items-end space-y-3">
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
        </div>
      </div>

      {/* Left side message skeleton */}
      <div className="flex items-start space-x-3 animate-pulse">
        <div className="w-8 h-8 rounded-full bg-gray-200"></div>
        <div
          className="tail_left h-8 w-48 bg-gray-200 rounded-lg"
        ></div>
        <div className="flex flex-col space-y-3">
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
        </div>
      </div>
{/* 
      <div className="flex items-start justify-end space-x-3 animate-pulse">
        <div className="flex flex-col items-end space-y-3">
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
          <div className="h-8 w-48 bg-gray-200 rounded-lg"></div>
        </div>
      </div> */}
      {/* Right side message skeleton */}
      {/* <div className="flex items-start justify-end space-x-2 animate-pulse">
        <div className="flex flex-col items-end space-y-2">
          <div className="h-4 w-48 bg-gray-200 rounded-lg"></div>
          <div className="h-4 w-36 bg-gray-200 rounded-lg"></div>
        </div>
      </div> */}

      {/* Loading indicator */}
      {/* <div className="flex items-center justify-center">
        <div className="flex space-x-1">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="w-2 h-2 bg-gray-300 rounded-full animate-bounce"
              style={{
                animationDelay: `${i * 0.2}s`,
                animationDuration: '1s'
              }}
            ></div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ChatSkeleton;