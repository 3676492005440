import React, { useState } from "react";
import { X } from 'lucide-react';
import "./FeedBackForm.css"; // Add corresponding CSS for styling
const PUBLIC_PATH = 'https://demo.turabit.com/dashboard_img'

const FeedbackForm: React.FC<{ onSubmitFeedback: (data: any) => void }> = ({ onSubmitFeedback }) => {
  const [rating, setRating] = useState<number>(0); // Store rating
  const [hoveredRating, setHoveredRating] = useState<number>(0); // Track hover state
  const [comment, setComment] = useState<string>(""); // Store comment
  const [alertMessage, setAlertMessage] = useState<string>(""); // Validation message
  // const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(
  //   !!localStorage.getItem("feedback_submitted")
  // ); // Check if feedback is already submitted
  // const [submissionMessage, setSubmissionMessage] = useState<string>("");
  // const SOCKET_SERVER_URL = 'https://stage-websocketadapter01.turabit.com/v1';

  const handleStarClick = (value: number) => {
    setRating(value);
    setAlertMessage(""); // Clear validation alert
  };

  const handleMouseOver = (value: number) => {
    setHoveredRating(value);
  };

  const handleMouseOut = () => {
    setHoveredRating(0);
  };

  const handleSubmit = async () => {
    if (rating === 0 && comment.trim() === "") {
      setAlertMessage("* Please either provide a rating or comment.");
      return;
    }

    // Prepare the feedback data
    const feedbackData = {
      rating,
      comment: comment.trim(),
    };
    onSubmitFeedback(feedbackData);
    
    // Simulate an API call (replace with real API endpoint)
    // try {
    //   const response = await fetch(SOCKET_SERVER_URL + "/feedback-rating", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(feedbackData),
    //   });

    //   const result = await response.json();
    //   if (result?.status === "true") {
    //     localStorage.setItem("feedback_submitted", "true");
    //     setFeedbackSubmitted(true);
    //     setSubmissionMessage("Feedback Submitted!");
    //   } else {
    //     setSubmissionMessage("Failed to submit Feedback. Try again later.");
    //   }
    // } catch (error) {
    //   console.error("Error submitting feedback:", error);
    //   setSubmissionMessage("Failed to submit Feedback. Try again later.");
    // }
  };

  const handleClose = () => {
    onSubmitFeedback("close");

  };
  return (
    <div className="feedback-container">
      <button className="feedback_close" onClick={handleClose}>
        <X size={20} />
      </button>
      <div className="feed_img">
        <img src={`${PUBLIC_PATH}/feedback_send_img.svg`} alt="" />
      </div>
      <div className="feedback_star">
        <h1>Send as your feedback!</h1>
        <p>Overall, how would you rate your chat
          experience?</p>
        <div className="rating-widget">
          {[1, 2, 3, 4, 5].map((star) => (
            <label
              key={star}
              className={`star-label ${star <= (hoveredRating || rating) ? "orange" : ""}`}
              onMouseOver={() => handleMouseOver(star)}
              onMouseOut={handleMouseOut}
              onClick={() => handleStarClick(star)}
            >
              ★
            </label>
          ))}
        </div>
      </div>
      <div className="feedback-form">
        <label>Feedback</label>
        <textarea
          placeholder="Enter your feedback"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        {alertMessage && <p className="alert-message">{alertMessage}</p>}
        <div className="btn_feedback">
        <button onClick={handleSubmit} className="btn-submit">
          Submit
        </button>
        </div>
      </div>
      {/* {submissionMessage && <p className="submission-message">{submissionMessage}</p>} */}
    </div>
  );
};

export default FeedbackForm;
